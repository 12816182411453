import { FormControl, FormLabel, chakra } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { CheckboxCopyrightTransfer } from '@/modules/calculator-core/components/CheckboxCopyrightTransfer';
import { HelpPopover } from '@/modules/calculator-core/components/HelpPopover';
import { useCalculatorLanguage } from '@/modules/calculator-core/hooks/useCalculatorLanguage';
import { inputWidth } from '@/modules/calculator-core/constants';

export const CheckboxCopyrightTransferField = () => {
    const { language } = useCalculatorLanguage();
    const { t } = useTranslation();

    if (language !== 'pl') return <></>;

    return (
        <FormControl
            display="flex"
            gap={2}
            whiteSpace="nowrap"
            width={inputWidth}
            alignItems="center"
        >
            <CheckboxCopyrightTransfer />

            <chakra.div>
                <FormLabel m={0} fontWeight="normal !important" display="inline">
                    {t('calculator_landing.copyright_transfer.title')}
                </FormLabel>

                <HelpPopover header={t('calculator_landing.copyright_transfer.title')}>
                    {t('calculator_landing.copyright_transfer.help_info')}
                </HelpPopover>
            </chakra.div>
        </FormControl>
    );
};
