import {
    type PropsWithChildren,
    createContext,
    useContext,
    useEffect,
    Dispatch,
    SetStateAction,
} from 'react';

import {
    type CategoryTreeItemType,
    type FeaturedSubcategoryType,
    type SubcategoryItemType,
    useCategoryTreeStore,
} from '../hooks/useCategoryTreeStore';

export type SubcategoryType = SubcategoryItemType | FeaturedSubcategoryType;

export type CategoryTreeContextType = {
    featured: FeaturedSubcategoryType[];
    categories: CategoryTreeItemType[];
    category: number | null;
    setCategory: (cat: number | null) => void;
    subcategory: number | null;
    setSubcategory: (subcat: number | null) => void;
    setFeaturedSubcategory: (subcat: number | null) => void;
    showOther?: boolean;
    setShowOther?: Dispatch<SetStateAction<boolean>>;
    toggleOther: () => void;
};

export type CategoryTreeContextProviderProps = PropsWithChildren<{
    categories: CategoryTreeItemType[];
    featured: FeaturedSubcategoryType[];
    initialSubcategory: number | null;
    onChange?: (subcategory: any) => void;
}>;

export const CategoryTreeContext = createContext<CategoryTreeContextType>({
    featured: [],
    categories: [],
    category: null,
    subcategory: null,
    showOther: false,
    setCategory: () => {},
    setSubcategory: () => {},
    setFeaturedSubcategory: () => {},
    toggleOther: () => {},
});

export function useCategoryTreeContext() {
    return useContext(CategoryTreeContext);
}

export function CategoryTreeContextProvider(props: CategoryTreeContextProviderProps) {
    const { children, categories = [], featured = [], onChange, initialSubcategory } = props;
    const { state, setCategory, setSubcategory, setFeaturedSubcategory, toggleOther } =
        useCategoryTreeStore({
            featured,
            categories,
            subcategory: initialSubcategory,
        });

    useEffect(() => {
        if (typeof onChange !== 'function') return;
        onChange(state.subcategory);
    }, [state.subcategory]);

    return (
        <CategoryTreeContext.Provider
            value={{
                categories: state.categories,
                featured: state.featured,
                category: state.category,
                subcategory: state.subcategory,
                showOther: state.showOther,
                toggleOther,
                setCategory,
                setSubcategory,
                setFeaturedSubcategory,
            }}
        >
            {children}
        </CategoryTreeContext.Provider>
    );
}
