import { ReactComponent as copywriting } from './category-copywriting.svg';
import { ReactComponent as design } from './category-design.svg';
import { ReactComponent as other } from './category-other.svg';
import { ReactComponent as photography } from './category-photography.svg';
import { ReactComponent as programming } from './category-programming.svg';
import { ReactComponent as seo } from './category-seo.svg';
import { ReactComponent as video } from './category-video.svg';
import { ReactComponent as websites } from './category-websites.svg';

export const FAST_CATEGORY_ICONS: Record<string, React.FC<any>> = {
    copywriting,
    design,
    other,
    photography,
    programming,
    seo,
    video,
    websites,
};
