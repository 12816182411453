import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Returns the email validation regex and error message to use in react-hook-form rules
 * @returns object with value and message
 *
 * @example ```tsx
 * const { register } = useForm();
 * const email = useEmailErrorMessage();
 * <input {...register('email', { required: true, pattern: email })} />
 * ```
 */
export function useEmailErrorMessage() {
    const { t } = useTranslation();
    return useMemo(
        () => ({
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: t('common.errors.email_type'),
        }),
        [t],
    );
}
