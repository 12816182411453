import React, { useState, type ComponentProps } from 'react';
import {
    Select,
    type GroupBase,
    type OptionBase,
    chakraComponents,
    type SelectComponentsConfig,
    type ChakraStylesConfig,
} from 'chakra-react-select';
import { Flex, Image, chakra, useMediaQuery } from '@chakra-ui/react';

import { selectCurrencyStyles } from './styles';

export interface CurrencyOption extends OptionBase {
    label: string;
    value: string;
    icon: string;
}

export type SelectCurrencyProps = ComponentProps<any> & { styles?: ChakraStylesConfig };

export const CURRENCIES: CurrencyOption[] = [
    { label: 'PLN', value: 'PLN', icon: 'PL' },
    { label: 'EUR', value: 'EUR', icon: 'EU' },
    { label: 'GBP', value: 'GBP', icon: 'GBP' },
    { label: 'USD', value: 'USD', icon: 'USD' },
];

type SelectCurrencyComponentsConfig = Partial<
    SelectComponentsConfig<CurrencyOption, true, GroupBase<CurrencyOption>>
>;

const selectCurrencyComponents: SelectCurrencyComponentsConfig = {
    Control: ({ children, ...props }: any) => {
        const value = props.getValue();
        const icon: string = value?.[0]?.icon ?? 'WW';
        const {
            selectProps: { isReadOnly },
        } = props;

        return (
            <chakraComponents.Control {...props}>
                <Image
                    src={`/static/images/flags/${icon}.svg`}
                    fallbackSrc="/static/images/flags/WW.svg"
                    alt=""
                />
                {children?.[0]}
                {isReadOnly === false && children?.[1]}
            </chakraComponents.Control>
        );
    },

    Option: ({ children, ...props }: any) => {
        const {
            data: { icon },
        }: { data: CurrencyOption } = props;

        return (
            <chakraComponents.Option {...props}>
                <Flex gap={3} alignItems="center">
                    <Image
                        src={`/static/images/flags/${icon}.svg`}
                        fallbackSrc="/static/images/flags/WW.svg"
                        alt=""
                    />
                    <chakra.span>{children}</chakra.span>
                </Flex>
            </chakraComponents.Option>
        );
    },
};

export const SelectCurrency = React.forwardRef(function SelectCurrency(
    { onChange, styles, ...props }: SelectCurrencyProps,
    forwardedRef,
) {
    const ref = React.useRef(forwardedRef);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isTouch] = useMediaQuery('(pointer: coarse)');

    return (
        <Select<CurrencyOption, true, GroupBase<CurrencyOption>>
            ref={ref}
            options={CURRENCIES}
            isSearchable={false}
            menuIsOpen={isTouch ? isOpen : undefined}
            onMenuOpen={isTouch ? () => setIsOpen(!isOpen) : undefined}
            onMenuClose={isTouch ? () => {} : undefined}
            chakraStyles={styles ?? selectCurrencyStyles}
            components={selectCurrencyComponents}
            onChange={(option: CurrencyOption) => {
                setIsOpen(false);
                if (typeof onChange === 'function') onChange(option);
            }}
            {...props}
        />
    );
});
