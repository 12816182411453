import { type ChakraStylesConfig } from 'chakra-react-select';

export const baseSelectCurrencyStyles: ChakraStylesConfig = {
    container: (provided) => ({
        ...provided,
        bg: 'transparent',
        m: 0,
        mr: 1,
    }),

    singleValue: (provided) => ({
        ...provided,
        textOverflow: 'clip !important',
    }),

    control: (provided) => ({
        ...provided,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        bg: 'transparent !important',
        border: '5px solid transparent !important',
        minH: '100% !important',
        gap: 1,
        _focus: {
            bg: '#fff !important',
            boxShadow: '0 0 0 1px #4955ff',
        },
        _active: {
            zIndex: 1,
        },
    }),

    valueContainer: (provided) => ({
        ...provided,
        display: 'flex',
        bg: 'transparent',
        fontWeight: 'bold !important',
        h: '100%',
        cursor: 'default',
        p: 0,
        fontSize: '1.8rem',
    }),

    dropdownIndicator: (provided) => ({
        ...provided,
        bg: 'transparent',
        p: 0,
    }),
};

export const selectCurrencyStyles: ChakraStylesConfig = {
    ...baseSelectCurrencyStyles,

    singleValue: (provided, state) => ({
        ...baseSelectCurrencyStyles.singleValue?.(provided, state),
        ml: 1,
    }),

    control: (provided, state) => ({
        ...baseSelectCurrencyStyles.control?.(provided, state),
        w: '11.5rem',
        img: {
            h: 8,
            w: 8,
        },
    }),

    dropdownIndicator: (provided, state) => ({
        ...baseSelectCurrencyStyles.dropdownIndicator?.(provided, state),
        color: 'general.blue',
    }),

    option: (provided) => ({
        ...provided,
        color: 'general.darkBlue',
        bg: 'transparent !important',
        _hover: {
            color: 'general.darkBlue',
            bg: 'general.lightBlue !important',
        },
    }),
};

export const newSelectCurrencyStyles: ChakraStylesConfig = {
    ...baseSelectCurrencyStyles,

    container: (provided, state) => ({
        ...baseSelectCurrencyStyles.container?.(provided, state),
        mr: '2rem',
        mt: 1,
    }),

    singleValue: (provided, state) => ({
        ...baseSelectCurrencyStyles.singleValue?.(provided, state),
        ml: 1.5,
    }),

    control: (provided, state) => ({
        ...baseSelectCurrencyStyles.control?.(provided, state),
        img: {
            h: '1.7rem',
            w: '2.4rem',
        },
    }),

    dropdownIndicator: (provided, state) => ({
        ...baseSelectCurrencyStyles.dropdownIndicator?.(provided, state),
        color: 'general.font',
    }),

    option: (provided) => ({
        ...provided,
        color: 'general.font',
        bg: 'transparent !important',
        _hover: {
            color: 'general.font',
            bg: 'general.menuGray !important',
        },
    }),
};
