import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { fetchPromoCode } from '../../services/promo-codes';

export interface ValidationResult {
    code: string;
    discount?: number;
}

export const usePromoCode = (
    props?: UseMutationOptions<ValidationResult, unknown, string, unknown>,
) => {
    return useMutation<ValidationResult, unknown, string, unknown>({
        mutationFn: async (input) => {
            return await fetchPromoCode<ValidationResult>({ code: input });
        },
        ...props,
    });
};
