import { chakra, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
    CURRENCIES,
    type CurrencyOption,
    SelectCurrency,
    type SelectCurrencyProps,
} from './SelectCurrency';

export type SelectCurrencyFieldProps = { onChange?: (value: string) => void } & Omit<
    SelectCurrencyProps,
    'onChange'
>;

export const SelectCurrencyField = ({
    onChange: onValueChange,
    ...props
}: SelectCurrencyFieldProps) => {
    const { t } = useTranslation();
    const { control } = useFormContext();
    const triggerRef = useRef<HTMLButtonElement>(null);

    return (
        <Controller
            control={control}
            name="currency"
            rules={{ required: t('common.errors.required') }}
            render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
                <FormControl id={name} isInvalid={error !== undefined}>
                    <SelectCurrency
                        ref={ref}
                        name={name}
                        value={CURRENCIES.find((c) => c.value === value)}
                        onChange={(option: CurrencyOption) => {
                            onChange(option.value);
                            if (typeof onValueChange === 'function') onValueChange(option.value);
                            triggerRef.current?.click();
                        }}
                        onBlur={onBlur}
                        {...props}
                    />
                    <FormErrorMessage>{error?.message}</FormErrorMessage>
                    <chakra.button type="submit" ref={triggerRef} display="none" />
                </FormControl>
            )}
        />
    );
};
