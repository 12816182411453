import { type ComponentProps } from 'react';
import { HStack, Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { usePersonalCommission } from '../../hooks/usePersonalCommission';

export const CalculatorHeader = (props: ComponentProps<any>) => {
    const { children } = props;

    return (
        <HStack spacing={4} userSelect="none" justifyContent={{ base: 'center', lg: 'flex-start' }}>
            <Heading as="h6" size="xs" color="general.darkBlue" ml={{ base: 0, lg: 1 }}>
                {children}
            </Heading>
        </HStack>
    );
};

export const FreelancerHeader = () => {
    const { t } = useTranslation();

    return <CalculatorHeader>{t('calculator.freelancer_header')}</CalculatorHeader>;
};

export const EmployerHeader = () => {
    const { t } = useTranslation();
    const { isPersonalCommission } = usePersonalCommission();

    return (
        <CalculatorHeader>
            {isPersonalCommission ? t('calculator.contract') : t('calculator.employer_header')}
        </CalculatorHeader>
    );
};
