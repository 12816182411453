import { type Dispatch, useCallback, useReducer } from 'react';

import { type BillingResult } from '@/modules/api/hooks/useBilling';

export interface UseCalculatorStoreProps {
    currency?: string;
}

export interface UseCalculatorStoreReturn extends BillingResult {
    getPayinItem: (label: string) => string | number | null;
    getPayoutItem: (label: string) => string | number | null;
    getPriceComponent: (label: string) => string | number | null;
    setCalculatorState: Dispatch<BillingResult>;
}

const calculatorStoreReducer = (state: BillingResult, newState: BillingResult): BillingResult => {
    return {
        ...state,
        payin: newState?.payin,
        payout: newState?.payout,
        priceComponents: newState?.priceComponents,
        currency: newState?.currency,
    };
};

export const useCalculatorStore = (props?: UseCalculatorStoreProps): UseCalculatorStoreReturn => {
    const [{ currency, payin, payout, priceComponents }, setCalculatorState]: [
        BillingResult,
        Dispatch<BillingResult>,
    ] = useReducer(calculatorStoreReducer, {
        payin: [],
        payout: [],
        priceComponents: [],
        currency: props?.currency ?? '',
    });

    const getPayinItem = useCallback(
        (label: string) => payin?.find((item) => item.label === label)?.value ?? null,
        [payin],
    );
    const getPayoutItem = useCallback(
        (label: string) => payout?.find((item) => item.label === label)?.value ?? null,
        [payout],
    );
    const getPriceComponent = useCallback(
        (label: string) => priceComponents?.find((item) => item.label === label)?.value ?? null,
        [priceComponents],
    );

    return {
        currency,
        payin,
        payout,
        priceComponents,
        getPayinItem,
        getPayoutItem,
        getPriceComponent,
        setCalculatorState,
    };
};
