import { EmployerSummary } from '../../components/EmployerSummary';
import { type InitialSummaryProps, SummaryContextProvider } from '../../contexts/SummaryContext';

export const EmployerDealSummary = (props: InitialSummaryProps) => {
    return (
        <SummaryContextProvider {...props}>
            <EmployerSummary />
        </SummaryContextProvider>
    );
};
