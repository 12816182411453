import { FieldValues, useForm } from 'react-hook-form';

/**
 * Handle form errors from rest api errors, and set them to form
 * @param form - form from useForm hook (react-hook-form)
 * @param map - map of errors from api to form fields, if not provided, it will use the same key as error key
 * @returns function to handle errors, it will set given errors to form fields
 *
 * @example
 * const form = useForm();
 * const handleErrors = useHandleFormErrors(form, {
 *    key_from_api: 'keyFromForm',
 * });
 */
export function useHandleFormErrors<T extends FieldValues = any>(
    form: ReturnType<typeof useForm<T>>,
    map: Record<string, string> = {},
) {
    /**
     * Handle errors from rest api response, and set them to form
     * @param errors - errors from rest api response
     *
     * @example
     * const form = useForm();
     * const handleErrors = useHandleFormErrors(form);
     * async function handleSubmit(data: any) {
     *   const result = await someApi(data);
     *   if (result?.errors !== undefined) {
     *     return handleErrors(result.errors);
     *   }
     * }
     */
    return function handleErrors(errors: Record<string, string>) {
        const keys = Object.keys(errors);
        keys.forEach((key: string) => {
            form.setError((map?.[key] ?? key) as any, {
                type: 'manual',
                message: errors[key],
            });
        });
    };
}
