import { memo } from 'react';
import { Container, chakra } from '@chakra-ui/react';

import { CalculatorFormLayout } from '../CalculatorFormLayout';

export const FastCalculatorLayout = memo(() => {
    return (
        <Container maxW="container.xl" px={0}>
            <chakra.div
                maxWidth="100%"
                borderRadius="xl"
                px={{ base: 4, md: 8 }}
                py={6}
                bg="general.skyBlue"
            >
                <CalculatorFormLayout />
            </chakra.div>
        </Container>
    );
});
