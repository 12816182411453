import { useCallback } from 'react';

export const useDataLayer = () => {
    const sendToGTM = useCallback((data: object) => {
        window.dataLayer = window.dataLayer ?? [];
        window.dataLayer.push(data);
    }, []);

    return {
        sendToGTM,
    };
};
