import { type ComponentProps } from 'react';
import {
    Flex,
    Accordion,
    AccordionItem,
    AccordionIcon,
    AccordionButton,
    AccordionPanel,
    Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { InputPromoCode } from '../InputPromoCode';

export const PromoCodeField = (props: ComponentProps<any>) => {
    const { t } = useTranslation();
    const { watch } = useFormContext();

    const discount = watch('discount');

    return (
        <Accordion allowToggle defaultIndex={(discount as boolean) ? 0 : null} {...props}>
            <AccordionItem css={{ '& .chakra-collapse': { overflow: 'visible !important' } }}>
                <AccordionButton>
                    <Flex flex={1}>
                        <Text>{t('calculator.promo_code.title')}</Text>
                    </Flex>
                    <AccordionIcon />
                </AccordionButton>

                <AccordionPanel tabIndex={0} outline="none">
                    <InputPromoCode discount={discount} />
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    );
};
