import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { fetchBilling } from '../../services/calculator';

export interface BillingItem {
    label: string;
    name: string;
    value: number;
}

export interface BillingResult {
    currency: string;
    payin: BillingItem[];
    payout: BillingItem[];
    priceComponents: BillingItem[];
}

export enum BillingCalculator {
    N2G = 'N2G',
    G2N = 'G2N',
    GROSS = 'GROSS',
}

export enum BillingUserClass {
    default = 'default',
    loyal = 'loyal',
}

export enum BillingCopyrightTransfer {
    license = 'license',
    protocol = 'protocol',
    without = 'without',
}

export interface BillingInputs {
    amount?: string | number | null;
    contractorEmail: string | null;
    contractorCountry: string;
    contractorIsBusiness: boolean;
    contractorIsVatPayer: boolean;
    contractorUserClass: BillingUserClass;
    copyrightTransfer: BillingCopyrightTransfer;
    currency: string;
    employerEmail: string | null;
    employerCountry: string;
    employerIsBusiness: boolean;
    employerIsVatPayer: boolean;
    employerUserClass: BillingUserClass;
    subcategory: number;
    usemeClub: boolean;
    billingCalculator: BillingCalculator;
    disallowEmail?: boolean;
    personalCommission?: boolean;
    showCopyrightTransfer?: boolean;
    isLogged?: boolean;
    isAchievedCommissionCap?: boolean;
    discount?: number;
}

export type UseBillingOptions = UseMutationOptions<BillingResult, unknown, BillingInputs, unknown>;

export const useBilling = (billingOptions?: UseBillingOptions) => {
    const { onError, ...options } = billingOptions ?? {};

    return useMutation<BillingResult, unknown, BillingInputs, unknown>({
        mutationFn: async (inputs) => {
            return await fetchBilling<any>({
                amount: inputs.amount ?? 0,
                copyright_transfer: inputs.copyrightTransfer ?? 'without',
                currency: inputs.currency ?? 'PLN',
                subcategory: inputs.subcategory,
                billing_calculator: inputs.billingCalculator,
                contractor: {
                    email: inputs.disallowEmail === true ? null : inputs.contractorEmail,
                    country: inputs.contractorCountry,
                    is_business: inputs.contractorIsBusiness,
                    is_vat_payer: inputs.contractorIsVatPayer,
                    user_class: inputs.usemeClub
                        ? BillingUserClass.loyal
                        : BillingUserClass.default,
                },
                employer: {
                    email: inputs.employerEmail,
                    country: inputs.employerCountry,
                    is_business: inputs.employerIsBusiness,
                    is_vat_payer: inputs.employerIsVatPayer,
                    user_class: inputs.usemeClub
                        ? BillingUserClass.loyal
                        : BillingUserClass.default,
                },
                discount: inputs?.discount,
            }).catch((error) => {
                if (typeof onError === 'function') onError(error, inputs, null);
                return false;
            });
        },
        ...options,
    });
};
