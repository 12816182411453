import { useFormContext } from 'react-hook-form';

import {
    useCalculatorContext,
    type InitialProps,
} from '@/modules/calculator-core/contexts/CalculatorContext';
import { CalculatorProvider } from '@/modules/calculator-core/components/CalculatorProvider';
import { BillingCalculator } from '@/modules/api/hooks/useBilling';
import { useCalculatorLanguage } from '@/modules/calculator-core/hooks/useCalculatorLanguage';

import { FreelancerSummary } from '../../components/FreelancerSummary';
import { SummaryContext } from '../../contexts/SummaryContext/SummaryContext';

const SecpaySummaryAdapter = () => {
    const { watch } = useFormContext();
    const billingCalculator: BillingCalculator = watch('billingCalculator');
    const { getPayinItem, getPayoutItem, getPriceComponent, currency } = useCalculatorContext();
    const { language } = useCalculatorLanguage();

    return (
        <SummaryContext.Provider
            value={{
                billingCalculator,
                currency,
                getPayinItem,
                getPayoutItem,
                getPriceComponent,
                language,
            }}
        >
            <FreelancerSummary />
        </SummaryContext.Provider>
    );
};

export const SecpaySummary = (props: InitialProps) => {
    return (
        <CalculatorProvider allowAutoStart={true} disableAnalytics={true} {...props}>
            <SecpaySummaryAdapter />
        </CalculatorProvider>
    );
};
