import { Box, Flex, FormControl, FormLabel, InputGroup, InputRightElement } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { SelectCurrencyField } from '@/modules/core/components/SelectCurrency';
import { useCalculatorType } from '@/modules/calculator-core/hooks/useCalculatorType';
import { useResult } from '@/modules/calculator-core/hooks/useResult';
import { newSelectCurrencyStyles } from '@/modules/core/components/SelectCurrency/styles';

import { FieldVatInfo } from '../FieldVatInfo';

export const ResultField = () => {
    const { t } = useTranslation();
    const { isGrossToNetto } = useCalculatorType();
    const { result, isVat } = useResult();

    return (
        <InputGroup>
            <FormControl variant="fast" h="7.8rem" isReadOnly layerStyle="shadowedField">
                <FormLabel color="general.subheading" fontWeight="400">
                    {isGrossToNetto
                        ? t('calculator.payout')
                        : `${t('calculator.invoice')} (${t('calculator.contract').toLowerCase()})`}
                </FormLabel>

                <Box
                    textStyle="h4"
                    fontSize={
                        result !== null && isVat && result.toString().length > 5
                            ? '1.8rem'
                            : '2.3rem'
                    }
                    color="general.heading"
                    pt={8}
                    px={5}
                >
                    {result}
                </Box>

                {result !== null && isVat && result.toString().length < 10 && (
                    <FieldVatInfo value={result} mt={1} />
                )}
            </FormControl>

            <InputRightElement w="auto" h="100%" mt={1}>
                <Flex alignContent="center" pointerEvents="none">
                    <SelectCurrencyField styles={newSelectCurrencyStyles} isReadOnly />
                </Flex>
            </InputRightElement>
        </InputGroup>
    );
};
