import { useMemo, memo } from 'react';
import { chakra, Text } from '@chakra-ui/react';
import { useTranslation, Trans } from 'react-i18next';

import { useGlobalContext } from '@/modules/core/contexts/GlobalContext';
import { useCalculatorContext } from '@/modules/calculator-core/contexts/CalculatorContext';
import { LangCurrency } from '@/modules/core/components/LangCurrency';

import { CalculatorFormLayout } from '../CalculatorFormLayout';
import {
    LandingContainer,
    LandingGrid,
    LandingGridItem,
    LandingHeading,
    type LandingPaymentDetails,
} from '../LandingCalculatorLayout';
import { ButtonLinkAndPaymentMethods } from '../ButtonLinkAndPaymentMethods';

export const PricingCalculatorLayout = () => {
    const { paymentDetails } = useCalculatorContext();
    return <BasePricingCalculatorLayout paymentDetails={paymentDetails} />;
};

export const BasePricingCalculatorLayout = memo(({ paymentDetails }: LandingPaymentDetails) => {
    const { t } = useTranslation();
    const { currentLanguage } = useGlobalContext();

    const buttonLinkProps = useMemo(
        () => ({
            id: `${currentLanguage}-pricing-calculator`,
            text:
                paymentDetails.isLogged === true
                    ? t('calculator_pricing_freelancer.layout_section.link_label.logged_user')
                    : t('calculator_pricing_freelancer.layout_section.link_label.guest_user'),
            href: `/${currentLanguage}/${
                paymentDetails.isLogged === true ? 'fast' : 'users/create-account/'
            }`,
        }),
        [],
    );

    return (
        <LandingContainer>
            <LandingGrid>
                <LandingGridItem w="100%" order={{ base: 2, xl: 1 }}>
                    <CalculatorFormLayout />
                    <ButtonLinkAndPaymentMethods
                        {...buttonLinkProps}
                        display={{ base: 'block', xl: 'none' }}
                    />
                </LandingGridItem>
                <LandingGridItem
                    pl={1}
                    pr={2}
                    order={{ base: 1, xl: 2 }}
                    textAlign={{ base: 'center', xl: 'left' }}
                >
                    <Text textStyle="caption" color="general.caption" mb={8}>
                        {t('calculator_pricing_freelancer.layout_section.caption')}
                    </Text>
                    <LandingHeading as="h2">
                        {t('calculator_pricing_freelancer.layout_section.heading')}
                    </LandingHeading>
                    <Text textStyle="h5" color="general.subheading" mb={{ base: 0, xl: 10 }}>
                        <Trans
                            i18nKey="calculator_pricing_freelancer.layout_section.subtext"
                            components={{ b: <chakra.b />, currency: <LangCurrency /> }}
                        />
                    </Text>
                    <ButtonLinkAndPaymentMethods
                        {...buttonLinkProps}
                        display={{ base: 'none', xl: 'block' }}
                    />
                </LandingGridItem>
            </LandingGrid>
        </LandingContainer>
    );
});
