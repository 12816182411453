import { FreelancerSummary } from '../../components/FreelancerSummary';
import { type InitialSummaryProps, SummaryContextProvider } from '../../contexts/SummaryContext';

export const FreelancerDealSummary = (props: InitialSummaryProps) => {
    return (
        <SummaryContextProvider {...props}>
            <FreelancerSummary />
        </SummaryContextProvider>
    );
};
