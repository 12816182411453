import { type ChangeEvent, type ComponentProps } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { Input } from '@chakra-ui/react';

export type InputPriceProps = { onChange?: ChangeEvent<HTMLInputElement> } & ComponentProps<any>;

export const InputPrice = ({ onChange: onValueChange, ...props }: InputPriceProps) => {
    const { t } = useTranslation();
    const { control } = useFormContext();

    const {
        field: { name, value, onChange, onBlur },
    } = useController({
        name: 'amount',
        control,
        rules: {
            required: t('common.errors.required'),
            min: { value: 1, message: t('common.errors.min') },
            max: { value: 999999.99, message: t('common.errors.max') },
        },
    });

    return (
        <NumericFormat
            customInput={Input}
            name={name}
            value={value}
            onChange={(e) => {
                onChange(e);
                if (typeof onValueChange === 'function') onValueChange(e);
            }}
            onBlur={onBlur}
            decimalSeparator="."
            decimalScale={2}
            {...props}
        />
    );
};
