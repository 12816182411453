import styled from '@emotion/styled';
import { type ChangeEventHandler, type ComponentProps, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, VisuallyHiddenInput, chakra } from '@chakra-ui/react';

import { breakpoint } from '@/modules/design/utils/breakpoint';

import { FAST_CATEGORY_ICONS } from '../FastCategoryIcons';
import { useCategoryTreeContext } from '../../contexts/CategoryTreeContext';
import { CategoryTree } from '../CategoryTree';
import { FeaturedSubcategoryType } from '../../hooks/useCategoryTreeStore';
import { useSendCategoryGTM } from '../../hooks/useSendCategoryGTM';

export function CategoryChoose(props: ComponentProps<typeof CategoryChooseList>) {
    const context = useCategoryTreeContext();
    const { t } = useTranslation();
    const sendCategoryGTM = useSendCategoryGTM();

    return (
        <chakra.div>
            <CategoryChooseList mb="6" {...props}>
                {context.featured.map((cat: any) => (
                    <chakra.li key={cat.id}>
                        <CategoryChooseItem
                            {...cat}
                            isSelected={context.subcategory === cat.id}
                            onClick={() => context.setFeaturedSubcategory(cat.id)}
                            onChange={sendCategoryGTM}
                        />
                    </chakra.li>
                ))}
            </CategoryChooseList>

            <Button
                type="button"
                onClick={context.toggleOther}
                colorScheme="lightGray"
                fontWeight="normal"
                w="100%"
                mb={6}
                sx={context.showOther === true ? fastShowCategoryButtonSelectedStyles : undefined}
            >
                {t('category_tree.all')}
            </Button>

            {context.showOther === true && <CategoryTree />}
        </chakra.div>
    );
}

const fastShowCategoryButtonSelectedStyles = {
    background: '#0047ff',
    color: '#fff',
    _hover: {
        background: '#0047ff',
        color: '#fff',
    },
    _focus: {
        background: '#0047ff',
        color: '#fff',
    },
    _active: {
        background: '#414ce5',
        color: '#fff',
    },
};

export const CategoryChooseList = styled(chakra.ul)`
    list-style: none;
    display: grid;
    gap: 2rem;
    align-content: stretch;
    grid-template-columns: 1fr;

    ${breakpoint('sm')} {
        grid-template-columns: repeat(2, 1fr);
    }

    ${breakpoint('md')} {
        grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    }

    ${breakpoint('lg')} {
        grid-template-columns: repeat(7, 1fr);
    }
`;

export function CategoryChooseItem(
    props: ComponentProps<typeof StyledCategoryChooseItem> &
        FeaturedSubcategoryType & {
            isSelected: boolean;
            onChange?: ChangeEventHandler<HTMLInputElement>;
        },
) {
    const { id, name, icon, onChange, isSelected, ...rest } = props;
    const Icon = useMemo(() => FAST_CATEGORY_ICONS[icon] ?? null, [icon]);

    return (
        <StyledCategoryChooseItem {...rest} isSelected={isSelected}>
            {Icon !== null && (
                <StyledCategoryChooseItemIcon isSelected={isSelected}>
                    <Icon />
                </StyledCategoryChooseItemIcon>
            )}
            <StyledCategoryChooseItemName isSelected={isSelected}>
                {name}
            </StyledCategoryChooseItemName>
            <VisuallyHiddenInput
                type="radio"
                name="featured_subcategory"
                value={id}
                tabIndex={0}
                onChange={onChange}
            />
        </StyledCategoryChooseItem>
    );
}

export const StyledCategoryChooseItem = styled(chakra.label, {
    shouldForwardProp: (prop) => prop !== 'isSelected',
})`
    background: #f7f7ff;
    color: var(--ck-colors-general-font);
    border-radius: 2rem;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.15s;
    width: 100%;
    height: 100%;

    &:hover,
    &:focus {
        background: #eeeef9;
        color: var(--ck-colors-general-font);
    }

    &:focus-within {
        box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.05), 0px 0px 6px rgba(0, 0, 0, 0.1);
    }

    ${(props: any) =>
        Boolean(props?.isSelected) &&
        `
        &, &:hover, &:focus {
            background: #4955ff;
            color: white;
        }
    `}
`;

export const StyledCategoryChooseItemName = styled(chakra.span, {
    shouldForwardProp: (prop) => prop !== 'isSelected',
})`
    display: block;
    color: inherit;
    width: 100%;
    margin-top: auto;
    align-self: flex-end;
    text-align: center;
    padding: 1rem;
    font-size: 1.1rem;
    line-height: 1.5;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    word-wrap: break-word;
    overflow-wrap: anywhere;
    font-weight: normal;
    flex-grow: 1;

    ${(props: any) => Boolean(props?.isSelected) && `&, &:hover, &:focus { font-weight: 700; }`}
`;

export const StyledCategoryChooseItemIcon = styled(chakra.span, {
    shouldForwardProp: (prop) => prop !== 'isSelected',
})`
    max-width: 100%;
    height: 4.5rem;
    display: flex;
    align-content: center;
    align-items: center;
    align-self: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin: 1rem 0;
    margin-top: 0;
    text-align: center;
    user-select: none;
    pointer-events: none;
    color: #c9cce2;
    margin-top: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;

    svg {
        max-width: 100%;
        max-height: 100%;
    }

    ${(props: any) => Boolean(props?.isSelected) && `&, &:hover, &:focus { color: white; }`}
`;
