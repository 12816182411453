import { type ComponentProps } from 'react';
import { FormProvider } from 'react-hook-form';

import { CalculatorContextProvider, type InitialProps } from '../../contexts/CalculatorContext';
import { useCalculatorForm } from '../../hooks/useCalculatorForm';

export type CalculatorProviderProps = ComponentProps<any> & InitialProps;

export const CalculatorFormProvider = ({ children }: ComponentProps<any>) => {
    const { form, onSubmit, onChange } = useCalculatorForm();

    return (
        <FormProvider {...form}>
            <form
                onChange={(e) => {
                    // exception for InputPromoCode purpose
                    if ((e.target as HTMLInputElement)?.name !== 'promocode') {
                        void form.handleSubmit(onChange)(e);
                    }
                }}
                onSubmit={(e) => {
                    void form.handleSubmit(onSubmit)(e);
                }}
            >
                {children}
            </form>
        </FormProvider>
    );
};

export const CalculatorProvider = ({ children, ...props }: CalculatorProviderProps) => {
    return (
        <CalculatorContextProvider {...props}>
            <CalculatorFormProvider>{children}</CalculatorFormProvider>
        </CalculatorContextProvider>
    );
};
