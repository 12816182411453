import { useMemo, memo } from 'react';
import { Grid, GridItem, Container, Heading, Text, type GridProps, chakra } from '@chakra-ui/react';
import { useTranslation, Trans } from 'react-i18next';
import styled from '@emotion/styled';

import { useGlobalContext } from '@/modules/core/contexts/GlobalContext';
import {
    useCalculatorContext,
    type CalculatorContextType,
} from '@/modules/calculator-core/contexts/CalculatorContext';

import { CalculatorFormLayout } from '../CalculatorFormLayout';
import { ButtonLinkAndPaymentMethods } from '../ButtonLinkAndPaymentMethods';

export type LandingCalculatorLayoutProps = {
    isEmbeddable?: boolean;
};

export type LandingPaymentDetails = {
    paymentDetails: CalculatorContextType['paymentDetails'];
};

export const LandingCalculatorLayout = (props: LandingCalculatorLayoutProps) => {
    const { paymentDetails } = useCalculatorContext();
    return <BaseLandingCalculatorLayout paymentDetails={paymentDetails} {...props} />;
};

export const BaseLandingCalculatorLayout = memo(
    ({ isEmbeddable, paymentDetails }: LandingCalculatorLayoutProps & LandingPaymentDetails) => {
        const { t } = useTranslation();
        const { currentLanguage } = useGlobalContext();

        const buttonLinkProps = useMemo(
            () => ({
                id: `${currentLanguage}-homepage-calculator`,
                text:
                    paymentDetails.isLogged === true
                        ? t('calculator_landing.layout_section.link_label.logged_user')
                        : t('calculator_landing.layout_section.link_label.guest_user'),
                href: `/${currentLanguage}/${
                    paymentDetails.isLogged === true ? 'fast' : 'users/create-account/'
                }`,
            }),
            [],
        );

        return (
            <LandingContainer>
                <LandingGrid>
                    <LandingGridItem w="100%" order={{ base: 2, xl: 1 }}>
                        <CalculatorFormLayout />
                        {(isEmbeddable === undefined || !isEmbeddable) && (
                            <ButtonLinkAndPaymentMethods
                                {...buttonLinkProps}
                                display={{ base: 'block', xl: 'none' }}
                            />
                        )}
                    </LandingGridItem>
                    <LandingGridItem
                        pl={1}
                        pr={2}
                        order={{ base: 1, xl: 2 }}
                        textAlign={{ base: 'center', xl: 'left' }}
                    >
                        <Text textStyle="caption" color="general.caption" mb={8}>
                            {t('calculator_landing.layout_section.caption')}
                        </Text>
                        <LandingHeading as="h2">
                            {t('calculator_landing.layout_section.heading')}
                        </LandingHeading>
                        <Text textStyle="h5" color="general.subheading" mb={{ base: 0, xl: 10 }}>
                            <Trans
                                i18nKey="calculator_landing.layout_section.subtext"
                                components={{ b: <chakra.b /> }}
                            />
                        </Text>
                        {(isEmbeddable === undefined || !isEmbeddable) && (
                            <ButtonLinkAndPaymentMethods
                                {...buttonLinkProps}
                                display={{ base: 'none', xl: 'block' }}
                            />
                        )}
                    </LandingGridItem>
                </LandingGrid>
            </LandingContainer>
        );
    },
);

export const LandingContainer = styled(Container)`
    max-width: var(--ck-sizes-container-xl);
    padding-inline-start: var(--ck-sizes-7);
    padding-inline-end: var(--ck-sizes-7);
`;

export const LandingGrid = ({ children, ...props }: GridProps) => (
    <Grid templateColumns={{ base: '1fr', xl: 'minmax(669px, 1fr) auto' }} gap={16} {...props}>
        {children}
    </Grid>
);

export const LandingGridItem = styled(GridItem)`
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

export const LandingHeading = styled(Heading)`
    font-size: var(--ck-fontSizes-5xl);
    font-weight: bold;
    line-height: 125% !important;
    letter-spacing: -0.8px;
    color: var(--ck-colors-general-heading);
    margin-bottom: var(--ck-space-10);
`;
