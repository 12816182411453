import { useCalculatorContext } from '../../contexts/CalculatorContext';
import { useCalculatorType } from '../useCalculatorType';

export const useResult = () => {
    const { isGrossToNetto } = useCalculatorType();
    const { getPayoutItem, getPayinItem } = useCalculatorContext();

    return {
        result: isGrossToNetto ? getPayoutItem('total') : getPayinItem('base_amount'),
        isVat: isGrossToNetto
            ? getPayoutItem('vat_amount') !== null
            : getPayinItem('vat_amount') !== null,
    };
};
