import { NumericFormat as BaseNumericFormat, type NumericFormatProps } from 'react-number-format';

export const NumericFormat = ({ suffix, ...rest }: NumericFormatProps) => (
    <BaseNumericFormat
        suffix={suffix !== undefined ? ' ' + suffix : undefined}
        thousandSeparator=" "
        decimalSeparator=","
        decimalScale={2}
        fixedDecimalScale={true}
        displayType="text"
        {...rest}
        style={{ whiteSpace: 'nowrap' }}
    />
);
