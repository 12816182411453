import {
    Flex,
    Accordion,
    AccordionItem,
    AccordionIcon,
    AccordionButton,
    AccordionPanel,
    Text,
    type AccordionProps,
    Grid,
    GridItem,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { NumericFormat } from '@/modules/core/components/NumericFormat';

import { useCalculatorContext } from '../../contexts/CalculatorContext';
import { InfoPopover } from '../InfoPopover';
import { type UseCalculatorStoreReturn } from '../../hooks/useCalculatorStore';
import { useCalculatorLanguage } from '../../hooks/useCalculatorLanguage';
import { usePersonalCommission } from '../../hooks/usePersonalCommission';

export interface CalculationDetailsProps extends AccordionProps {
    advanced?: boolean;
}

export const CalculationDetailsBase = (
    useStoreHook: CallableFunction,
    useLanguageHook: CallableFunction,
    usePersonalCommissionHook: CallableFunction,
    { children, advanced = true, ...props }: CalculationDetailsProps,
) => {
    const { t } = useTranslation();
    const { currency, getPriceComponent: getItem }: UseCalculatorStoreReturn = useStoreHook();
    const { isPersonalCommission }: { isPersonalCommission: boolean } = usePersonalCommissionHook();

    return (
        <Accordion allowToggle defaultIndex={0} {...props}>
            <AccordionItem css={{ '& .chakra-collapse': { overflow: 'visible !important' } }}>
                <AccordionButton>
                    <Flex flex={1}>
                        <Text>{t('calculator.calculation_details.title')}</Text>
                    </Flex>
                    <AccordionIcon />
                </AccordionButton>

                <AccordionPanel tabIndex={0}>
                    <Grid
                        templateColumns="min-content auto"
                        columnGap={4}
                        rowGap={5}
                        lineHeight="120%"
                        fontSize="sm"
                        color="general.gray"
                    >
                        {getItem('pit_advance') !== null && (
                            <>
                                <GridItem color="general.blue" textAlign="right">
                                    <NumericFormat
                                        value={getItem('pit_advance')}
                                        suffix={currency}
                                    />
                                </GridItem>
                                <GridItem>
                                    {t('calculator.calculation_details.income_tax_caption')}
                                    <InfoPopover
                                        header={t(
                                            'calculator.calculation_details.income_tax_caption',
                                        )}
                                    >
                                        {t('calculator.calculation_details.income_tax_text')}
                                    </InfoPopover>
                                </GridItem>
                            </>
                        )}
                        {advanced &&
                            getItem('vat_amount') !== null &&
                            getItem('gross_amount') !== null && (
                                <>
                                    <GridItem color="general.blue" textAlign="right">
                                        <NumericFormat
                                            value={getItem('vat_amount') ?? 0}
                                            suffix={currency}
                                        />
                                    </GridItem>

                                    <GridItem>
                                        VAT (
                                        {t('calculator.calculation_details.commission.vat_caption')}
                                        {': '}
                                        <NumericFormat
                                            value={getItem('gross_amount') ?? 0}
                                            suffix={currency}
                                        />
                                        )
                                        <InfoPopover
                                            header={`VAT (${t(
                                                'calculator.calculation_details.commission.vat_caption',
                                            )})`}
                                        >
                                            {t(
                                                'calculator.calculation_details.commission.vat_text',
                                            )}
                                        </InfoPopover>
                                    </GridItem>
                                </>
                            )}
                        {getItem('commission') !== null && (
                            <>
                                {isPersonalCommission ? (
                                    <GridItem colSpan={2}>
                                        <Text as="span" color="general.blue" mr="2">
                                            {t(
                                                'calculator.calculation_details.commission.free_caption',
                                            )}
                                        </Text>
                                        {t('calculator.calculation_details.commission.free_text')}
                                    </GridItem>
                                ) : (
                                    <>
                                        {getItem('commission_without_discount') !== null &&
                                            getItem('commission_without_discount') !==
                                                getItem('commission') && (
                                                <>
                                                    <GridItem
                                                        textAlign="right"
                                                        color="general.mutedGray"
                                                        textDecoration="line-through"
                                                    >
                                                        <NumericFormat
                                                            value={getItem(
                                                                'commission_without_discount',
                                                            )}
                                                            suffix={currency}
                                                        />
                                                    </GridItem>
                                                    <GridItem
                                                        color="general.mutedGray"
                                                        textDecoration="line-through"
                                                    >
                                                        {t(
                                                            'calculator.calculation_details.commission.service_fee_caption',
                                                        )}
                                                    </GridItem>
                                                    <GridItem
                                                        color="general.blue"
                                                        textAlign="right"
                                                    >
                                                        <NumericFormat
                                                            value={getItem('discount_value')}
                                                            suffix={currency}
                                                        />
                                                    </GridItem>
                                                    <GridItem>
                                                        {t(
                                                            'calculator.calculation_details.promo_code',
                                                        )}
                                                    </GridItem>
                                                </>
                                            )}

                                        <GridItem
                                            color="general.blue"
                                            textAlign="right"
                                            fontWeight={
                                                getItem('discount_value') !== null
                                                    ? 'bold'
                                                    : 'normal'
                                            }
                                        >
                                            <NumericFormat
                                                value={getItem('commission') ?? 0}
                                                suffix={currency}
                                            />
                                        </GridItem>

                                        <GridItem>
                                            {t(
                                                'calculator.calculation_details.commission.service_fee_caption',
                                            )}
                                            <CommissionPopover />
                                        </GridItem>
                                    </>
                                )}
                            </>
                        )}

                        {children !== undefined && <GridItem colSpan={2}>{children}</GridItem>}
                    </Grid>
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    );
};

const CommissionPopover = () => {
    const { t } = useTranslation();
    return (
        <InfoPopover header={t('calculator.calculation_details.commission.service_fee_caption')}>
            {t('calculator.calculation_details.commission.service_fee_text')}
        </InfoPopover>
    );
};

export const CalculationDetails = CalculationDetailsBase.bind(
    {},
    useCalculatorContext,
    useCalculatorLanguage,
    usePersonalCommission,
);
