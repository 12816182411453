import { type ChangeEvent } from 'react';
import { Flex, Stack, Grid } from '@chakra-ui/react';

import { PriceAndCurrencyField } from '@/modules/calculator-core/components/PriceAndCurrencyField';
import { ResultField } from '@/modules/calculator-core/components/ResultField';
import { SwitchBillingType } from '@/modules/calculator-core/components/SwitchBillingType';
import { useCalculatorType } from '@/modules/calculator-core/hooks/useCalculatorType';
import { CalculationDetails } from '@/modules/calculator-core/components/CalculationDetails';
import { inputWidth } from '@/modules/calculator-core/constants';
import {
    EmployerHeader,
    FreelancerHeader,
} from '@/modules/calculator-core/components/CalculatorHeader';
import { MaxCommissionBanner } from '@/modules/calculator-core/components/MaxCommissionBanner';
import { PromoCodeField } from '@/modules/calculator-core/components/PromoCodeField';

export const CalculatorFormLayout = () => {
    const { isNettoToGross, isGrossToNetto } = useCalculatorType();

    return (
        <>
            <Grid
                templateColumns={{
                    base: '1fr',
                    lg: `minmax(${inputWidth}, 1fr) auto minmax(${inputWidth}, 1fr)`,
                }}
                columnGap={{ base: '1.3rem', lg: '0.2rem', xl: '1.3rem' }}
            >
                <Stack spacing={3}>
                    {isNettoToGross ? <FreelancerHeader /> : <EmployerHeader />}

                    <PriceAndCurrencyField
                        onChangeCurrency={(value: string) => {
                            const input: HTMLInputElement | null =
                                document.querySelector('#id_currency');
                            if (input !== null) {
                                input.value = value;
                            }
                        }}
                        onChangePrice={(e: ChangeEvent<HTMLInputElement>) => {
                            const input: HTMLInputElement | null =
                                document.querySelector('#id_payment');
                            if (input !== null) {
                                input.value = e.target.value;
                            }
                        }}
                    />
                </Stack>

                <Flex
                    alignItems="flex-start"
                    justifyItems="center"
                    mt={{ base: 4, lg: '5rem' }}
                    mb={{ base: 4, lg: 0 }}
                >
                    <SwitchBillingType
                        buttonProps={{ transform: { base: 'rotate(90deg)', lg: 'none' } }}
                        onChange={(_e, billingCalculator, amount) => {
                            const input: HTMLInputElement | null =
                                document.querySelector('#id_billing_calculator');
                            if (input !== null) {
                                input.value = billingCalculator;
                            }

                            const inputPayment: HTMLInputElement | null =
                                document.querySelector('#id_payment');
                            if (inputPayment !== null) {
                                inputPayment.value = amount?.toString() ?? '';
                            }
                        }}
                    />
                </Flex>

                <Stack spacing={3}>
                    {isGrossToNetto ? <FreelancerHeader /> : <EmployerHeader />}
                    <ResultField />
                </Stack>
            </Grid>

            <MaxCommissionBanner />

            <PromoCodeField />
            <CalculationDetails mt={4} />
        </>
    );
};
