import { type ComponentProps, createContext, useContext, useMemo } from 'react';

import { type BillingInputs } from '@/modules/api/hooks/useBilling';
import { parseFloatValue } from '@/modules/core/utils/parseFloatValue';

import { type UseCalculatorStoreReturn, useCalculatorStore } from '../../hooks/useCalculatorStore';

export interface CalculatorContextType extends UseCalculatorStoreReturn {
    paymentDetails: BillingInputs;
    autoStart: boolean;
    disallowEmail: boolean;
    disableAnalytics: boolean;
}

export const CalculatorContext: React.Context<CalculatorContextType> = createContext(null as any);

export interface InitialProps extends ComponentProps<any> {
    initial: string;
    allowAutoStart?: string | boolean;
    disallowEmail?: string | boolean;
    defaultAmount?: string | number;
    defaultContractorCountry?: string;
    defaultContractorIsBusiness?: boolean;
    disableAnalytics?: string | boolean;
}

const castIntoBoolean = (param: any): boolean => {
    return param !== undefined && (param === 'true' || param === true);
};

export function CalculatorContextProvider({
    children,
    initial,
    allowAutoStart,
    disallowEmail,
    defaultAmount,
    defaultContractorCountry,
    defaultContractorIsBusiness,
    disableAnalytics,
}: InitialProps) {
    const paymentDetails = useMemo(() => {
        const details = JSON.parse(initial);

        // Ignore currency from backend if user is not logged
        if (details.isLogged === false) {
            const userLanguage = navigator.language;

            details.currency = 'EUR';
            if (userLanguage.startsWith('es')) details.currency = 'USD';
            else if (userLanguage.startsWith('pl')) details.currency = 'PLN';
        }

        if (defaultAmount !== undefined) details.amount = defaultAmount;
        details.amount = parseFloatValue(details.amount);
        if (defaultContractorCountry !== undefined)
            details.contractorCountry = defaultContractorCountry;
        if (defaultContractorIsBusiness !== undefined)
            details.contractorIsBusiness = defaultContractorIsBusiness;
        return details;
    }, []);
    const store = useCalculatorStore({ currency: paymentDetails.currency });

    return (
        <CalculatorContext.Provider
            value={{
                ...store,
                paymentDetails,
                autoStart: castIntoBoolean(allowAutoStart),
                disallowEmail: castIntoBoolean(disallowEmail),
                disableAnalytics: castIntoBoolean(disableAnalytics),
            }}
        >
            {children}
        </CalculatorContext.Provider>
    );
}

export function useCalculatorContext() {
    return useContext(CalculatorContext);
}
