import { Icon } from '@chakra-ui/react';

import { SwitchBillingType as BaseSwitchBillingType } from '@/modules/calculator-core/components/SwitchBillingType';

import { ReactComponent as BillingSwitchIcon } from './billing-switch.svg';

export const SwitchBillingType = () => {
    return (
        <BaseSwitchBillingType
            buttonProps={{
                sx: switchBillingTypeStyles,
                icon: <Icon as={BillingSwitchIcon} w="4.8rem" h="4.8rem" />,
            }}
        />
    );
};

const switchBillingTypeStyles = {
    zIndex: 1,
    boxShadow: '0 5px 25px 0 rgba(65, 55, 181, 0.13)',
    border: 'none !important',
    position: { md: 'absolute !important' },
    '&:hover': { background: 'general.grayishBlue !important' },
};
