import { Dispatch, useCallback, useReducer } from 'react';

export type BaseCategoryItemType = {
    id: number;
    name: string;
};

export type CategoryTreeItemType = BaseCategoryItemType & {
    icon: string;
    subcategories: SubcategoryItemType[];
};

export type SubcategoryItemType = BaseCategoryItemType & {
    parent: number;
};

export type FeaturedSubcategoryType = SubcategoryItemType & {
    icon: string;
};

export type CategoryTreeState = {
    categories: CategoryTreeItemType[];
    featured: FeaturedSubcategoryType[];
    category: number | null;
    subcategory: number | null;
    showOther: boolean;
    isFeatured: boolean;
    subcategories: SubcategoryItemType[];
};

type ActionType<T, P = never> = {
    type: T;
    payload?: P;
};

type CategoryTreeActions =
    | ReturnType<typeof setCategoryAction>
    | ReturnType<typeof setSubcategoryAction>
    | ReturnType<typeof setFeaturedSubcategoryAction>
    | ReturnType<typeof toggleOtherAction>;

function getSubcategory(categories: CategoryTreeItemType[], id: number | null) {
    if (id === null) return null;

    const category = getSubcategoryParent(categories, id);
    if (category === null) return null;

    return category?.subcategories.find((subcat: any) => subcat.id === id) ?? null;
}

function getSubcategoryParent(categories: CategoryTreeItemType[], id: number | null) {
    if (id === null) return null;
    const category = categories.find((cat) => {
        return cat.subcategories.some((subcat) => id === subcat.id);
    });
    return category ?? null;
}

function getFeatured(featured: FeaturedSubcategoryType[], id: number | null) {
    if (id === null) return null;
    return featured.find((subcat: any) => subcat.id === id) ?? null;
}

function getCategory(categories: CategoryTreeItemType[], id: number | null) {
    if (id === null) return null;
    return categories.find((cat: any) => cat.id === id) ?? null;
}

function setCategoryAction(
    category: number | null,
): ActionType<'SET_CATEGORY', { category: number | null }> {
    return {
        type: 'SET_CATEGORY',
        payload: { category },
    };
}

function setSubcategoryAction(
    subcategory: number | null,
): ActionType<'SET_SUBCATEGORY', { subcategory: number | null }> {
    return {
        type: 'SET_SUBCATEGORY',
        payload: { subcategory },
    };
}

function setFeaturedSubcategoryAction(
    subcategory: number | null,
): ActionType<'SET_FEATURED_SUBCATEGORY', { subcategory: number | null }> {
    return {
        type: 'SET_FEATURED_SUBCATEGORY',
        payload: { subcategory },
    };
}

function toggleOtherAction(): ActionType<'TOGGLE_OTHER'> {
    return { type: 'TOGGLE_OTHER' };
}

function categoryTreeReducer(
    state: CategoryTreeState,
    action: CategoryTreeActions,
): CategoryTreeState {
    switch (action.type) {
        case 'SET_CATEGORY': {
            const category = getCategory(state.categories, action?.payload?.category ?? null);
            return {
                ...state,
                category: category?.id ?? null,
                subcategory: null,
                subcategories: category?.subcategories ?? [],
                isFeatured: false,
            };
        }

        case 'SET_SUBCATEGORY': {
            const subcat = getSubcategory(state.categories, action?.payload?.subcategory ?? null);
            const parent = getCategory(state.categories, subcat?.parent ?? null) ?? null;
            const isFeatured = Boolean(
                getFeatured(state.featured, action?.payload?.subcategory ?? null),
            );

            return {
                ...state,
                subcategory: subcat?.id ?? null,
                category: parent?.id ?? null,
                subcategories: parent?.subcategories ?? [],
                isFeatured,
            };
        }

        case 'SET_FEATURED_SUBCATEGORY': {
            const subcat = getSubcategory(state.categories, action?.payload?.subcategory ?? null);
            const parent = getCategory(state.categories, subcat?.parent ?? null) ?? null;
            const isFeatured = Boolean(
                getFeatured(state.featured, action?.payload?.subcategory ?? null),
            );

            return {
                ...state,
                subcategory: subcat?.id ?? null,
                category: parent?.id ?? null,
                subcategories: parent?.subcategories ?? [],
                isFeatured,
                showOther: false,
            };
        }

        case 'TOGGLE_OTHER': {
            return {
                ...state,
                subcategory: null,
                category: null,
                subcategories: [],
                showOther: !state.showOther,
            };
        }

        default:
            return state;
    }
}

export function useCategoryTreeStore(initial: {
    categories: CategoryTreeItemType[];
    featured: FeaturedSubcategoryType[];
    subcategory: number | null;
}) {
    const { categories = [], featured = [] } = initial;

    const [state, dispatch]: [CategoryTreeState, Dispatch<CategoryTreeActions>] = useReducer(
        categoryTreeReducer,
        {
            categories,
            featured,
        },
        (state) => {
            const subcategory = getSubcategory(state.categories, initial.subcategory);
            const parent = getSubcategoryParent(state.categories, initial.subcategory);
            const isFeatured = Boolean(getFeatured(state.featured, initial.subcategory ?? null));
            return {
                ...state,
                subcategory: subcategory?.id ?? null,
                category: parent?.id ?? null,
                showOther: initial.subcategory !== null && !isFeatured,
                isFeatured,
                subcategories: parent?.subcategories ?? [],
            };
        },
    );

    const setCategory = useCallback(
        (id: number | null) => dispatch(setCategoryAction(id)),
        [dispatch],
    );

    const setSubcategory = useCallback(
        (id: number | null) => dispatch(setSubcategoryAction(id)),
        [dispatch],
    );

    const setFeaturedSubcategory = useCallback(
        (id: number | null) => dispatch(setFeaturedSubcategoryAction(id)),
        [dispatch],
    );

    const toggleOther = useCallback(() => dispatch(toggleOtherAction()), [dispatch]);

    return {
        state,
        dispatch,
        setCategory,
        setSubcategory,
        setFeaturedSubcategory,
        toggleOther,
    };
}
