import { Button, type ButtonProps } from '@chakra-ui/react';

export type ButtonLinkProps = { href: string; target?: string } & ButtonProps;

export const ButtonLink = ({ href, target, onClick, ...buttonProps }: ButtonLinkProps) => {
    const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        onClick?.(e);
        if (target !== undefined) window.open(href, target);
        else window.location.href = href;
    };

    return <Button {...buttonProps} onClick={handleClick} />;
};
