import { useCsrfToken } from '../useCsrfToken';

export type UseFormRedirectConfig = {
    method: 'post' | 'get';
    action?: string;
};

/**
 * Redirects to a given URL by creating a form and submitting it.
 * @returns Function that accepts data to be sent to the URL
 * @example
 * const formRedirect = useFormRedirect({ method: 'post' });
 * await formRedirect({ email: data.email });
 */
export function useFormRedirect(config: UseFormRedirectConfig) {
    const { csrfmiddlewaretoken } = useCsrfToken();

    /**
     * Redirects to a given URL by creating a form and submitting it.
     * @param data - data to be sent to the URL as form fields
     * @see useFormRedirect hook
     * @example
     * const formRedirect = useFormRedirect({ method: 'post', action: '/some-url' });
     * await formRedirect({ email: data.email });
     */
    return function formRedirect(data: Record<string, string> = {}) {
        const redirect = document.createElement('form');
        redirect.method = config.method;
        redirect.classList.add('visually-hidden');

        if (typeof config.action !== 'undefined') {
            redirect.action = config.action;
        }

        const csrf = document.createElement('input');
        csrf.type = 'hidden';
        csrf.name = 'csrfmiddlewaretoken';
        csrf.value = csrfmiddlewaretoken;
        redirect.append(csrf);

        Object.entries(data).forEach(([key, value]) => {
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = key;
            input.value = value;
            redirect.append(input);
        });

        // Form that is not attached to the DOM will not submit
        document.body.append(redirect);
        redirect.submit();
    };
}
