import { useMemo } from 'react';
import { chakra } from '@chakra-ui/react';

import { ReactComponent as adminSupport } from './category-admin_support.svg';
import { ReactComponent as coding } from './category-coding.svg';
import { ReactComponent as copyrights } from './category-copyrights.svg';
import { ReactComponent as hosting } from './category-hosting.svg';
import { ReactComponent as marketing } from './category-marketing.svg';
import { ReactComponent as multimedia } from './category-multimedia.svg';
import { ReactComponent as others } from './category-others.svg';
import { ReactComponent as palette } from './category-palette.svg';
import { ReactComponent as socialMedia } from './category-social_media.svg';
import { ReactComponent as translations } from './category-translations.svg';
import { ReactComponent as video } from './category-video.svg';
import { ReactComponent as webDevelopment } from './category-web_development.svg';
import { ReactComponent as arrowBack } from './arrow-back.svg';

export const CATEGORY_ICONS: Record<string, React.FC<any>> = {
    'category-admin_support': chakra(adminSupport),
    'category-coding': chakra(coding),
    'category-copyrights': chakra(copyrights),
    'category-hosting': chakra(hosting),
    'category-marketing': chakra(marketing),
    'category-multimedia': chakra(multimedia),
    'category-others': chakra(others),
    'category-palette': chakra(palette),
    'category-social_media': chakra(socialMedia),
    'category-translations': chakra(translations),
    'category-video': chakra(video),
    'category-web_development': chakra(webDevelopment),
};

export const IconArrowBack = chakra(arrowBack);

export function useCategoryIcon(icon: string) {
    return useMemo(() => {
        if (icon in CATEGORY_ICONS) {
            return CATEGORY_ICONS[icon];
        }
        return CATEGORY_ICONS['category-others'];
    }, [icon]);
}
