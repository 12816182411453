import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { parseFloatValue } from '@/modules/core/utils/parseFloatValue';

export const useInitCalculatorFromExternalForm = () => {
    const { setValue } = useFormContext();

    const initCalculator = useCallback(() => {
        const payment: HTMLInputElement | null = document.querySelector('#id_payment');
        if (payment !== null && payment?.value !== '') {
            setValue('amount', parseFloatValue(payment?.value), { shouldValidate: true });
        }

        const currency: HTMLInputElement | null = document.querySelector('#id_currency');
        if (currency !== null && currency?.value !== '') {
            setValue('currency', currency?.value, { shouldValidate: true });
        }

        const billingCalculator: HTMLInputElement | null =
            document.querySelector('#id_billing_calculator');
        if (billingCalculator !== null && billingCalculator?.value !== '') {
            setValue('billingCalculator', billingCalculator?.value, { shouldValidate: true });
        }
    }, []);

    return {
        initCalculator,
    };
};
