import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    useMediaQuery,
    Icon,
    Portal,
} from '@chakra-ui/react';
import { type ComponentProps, type ReactNode } from 'react';

import { useAppContext } from '@/modules/core/contexts/AppContext';

import { ReactComponent as IconInfoSvg } from './icon-info.svg';

export type InfoPopoverProps = { header: ReactNode } & ComponentProps<any>;

export const InfoPopover = ({ header, children }: InfoPopoverProps) => {
    const [isTouch] = useMediaQuery('(pointer: coarse)');
    const { containerRef } = useAppContext();

    return (
        <Popover variant="dialog" trigger={isTouch ? 'click' : 'hover'}>
            <PopoverTrigger>
                <Icon
                    as={IconInfoSvg}
                    w={4}
                    h={4}
                    ml={1}
                    cursor="pointer"
                    verticalAlign="middle"
                    color="general.blue"
                />
            </PopoverTrigger>
            <Portal containerRef={containerRef}>
                <PopoverContent whiteSpace="normal">
                    <PopoverHeader>{header}</PopoverHeader>
                    <PopoverBody>{children}</PopoverBody>
                </PopoverContent>
            </Portal>
        </Popover>
    );
};
