import { type ComponentProps } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormControl, FormLabel, chakra, Text, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import {
    SelectCurrencyField,
    type SelectCurrencyFieldProps,
} from '@/modules/core/components/SelectCurrency';

import { InputPrice, type InputPriceProps } from '../InputPrice';
import { useCalculatorType } from '../../hooks/useCalculatorType';
import { FieldVatInfo } from '../FieldVatInfo';
import { useCalculatorContext } from '../../contexts/CalculatorContext';

export type PriceAndCurrencyFieldProps = {
    onChangePrice?: InputPriceProps['onChange'];
    onChangeCurrency?: SelectCurrencyFieldProps['onChange'];
} & ComponentProps<any>;

export const PriceAndCurrencyField = ({
    onChangePrice,
    onChangeCurrency,
}: PriceAndCurrencyFieldProps) => {
    const {
        formState: { errors },
    } = useFormContext();
    const { t } = useTranslation();
    const { isGrossToNetto } = useCalculatorType();
    const { getPayoutItem, getPayinItem } = useCalculatorContext();
    const { watch } = useFormContext();
    const amount = watch('amount');
    const isVat = isGrossToNetto
        ? getPayinItem('vat_amount') !== null
        : getPayoutItem('vat_amount') !== null;

    return (
        <chakra.div>
            <chakra.div position="relative">
                <FormControl variant="fast" h="8.1rem" isInvalid={errors.amount !== undefined}>
                    <FormLabel>
                        {isGrossToNetto
                            ? `${t('calculator.invoice')} (${t(
                                  'calculator.contract',
                              ).toLowerCase()})`
                            : t('calculator.payout')}
                    </FormLabel>

                    <InputPrice
                        variant="fast"
                        size="lg"
                        filter="drop-shadow(0px 0px 30px rgba(109, 96, 255, 0.13))"
                        fontSize={
                            (amount as boolean) && isVat && amount.toString().length > 6
                                ? '2rem'
                                : '2.4rem'
                        }
                        fontWeight="bold"
                        paddingInlineEnd="13rem"
                        paddingInlineStart={5}
                        onChange={onChangePrice}
                        pt={2}
                    />

                    {(amount as boolean) && isVat && errors.amount === undefined && (
                        <FieldVatInfo value={amount} />
                    )}
                </FormControl>

                <SelectCurrencyContainer>
                    <SelectCurrencyField onChange={onChangeCurrency} />
                </SelectCurrencyContainer>
            </chakra.div>

            {errors.amount !== undefined && (
                <Text textStyle="error" color="danger.font.base" mt={2}>
                    {errors.amount?.message}
                </Text>
            )}
        </chakra.div>
    );
};

const SelectCurrencyContainer = styled(Flex)`
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
    height: 100%;
    margin-top: var(--ck-space-1);
`;
