import { useState, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useSafeLayoutEffect, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { GoogleReCaptchaProvider } from '@/modules/core/hooks/useReCaptcha';
import { ssrContext } from '@/modules/core/contexts/GlobalContext';
import { useHandleFormErrors } from '@/modules/core/hooks/useHandleFormErrors';
import {
    SettleFreelancerInvoicePayload,
    settleFreelancerInvoice,
} from '@/modules/api/services/settle-freelancer-invoice';
import { useFormRedirect } from '@/modules/core/hooks/useFormRedirect';

import {
    SettleFreelancerForm,
    useSettleFreelancerForm,
} from '../../components/SettleFreelancerForm';

export default function SettleFreelancer() {
    const formRedirect = useFormRedirect({ method: 'post' });
    const { t } = useTranslation();
    const toast = useToast();

    const [step, setStep] = useState<number>(1);
    const form = useSettleFreelancerForm();
    const handleErrors = useHandleFormErrors(form, {
        freelancer_email: 'freelancerEmail',
        first_name: 'firstName',
        last_name: 'lastName',
        tax_id: 'taxId',
        newsletter_consent: 'newsletterConsent',
    });

    async function handleSubmit(data: SettleFreelancerInvoicePayload) {
        const result = await settleFreelancerInvoice(data);

        if (result?.errors !== undefined) {
            handleErrors(result.errors);

            const fieldsToErrorCheckOnStepOne = [
                'freelancer_email',
                'message',
                'newsletter_consent',
            ];
            if (Object.keys(result.errors).some((k) => fieldsToErrorCheckOnStepOne.includes(k))) {
                setStep(1);
            }

            toast({
                title: t('contact.form.message_error'),
                status: 'error',
                isClosable: true,
            });

            return;
        }

        window.dataLayer = Array.isArray(window.dataLayer) ? window.dataLayer : [];
        window.dataLayer.push({
            event: 'job_invitation_submit',
        });

        await formRedirect({ email: data.email });
    }

    useEffect(() => {
        if (form.formState.submitCount === 0 && step !== 1) {
            window.dataLayer = Array.isArray(window.dataLayer) ? window.dataLayer : [];
            window.dataLayer.push({
                event: 'job_invitation_init',
            });
        }
    }, [step]);

    useSafeLayoutEffect(() => {
        const landing = document.getElementById('settle-freelancer');
        if (landing === null) return;
        landing.setAttribute('data-step', `${step}`);

        const top = document.getElementById('top');
        if (top === null) return;
        const html = document.querySelector('html');
        if (step === 2 && html !== null) {
            html.scrollTo({ top: top.offsetTop, behavior: 'smooth' });
        }
    }, [step]);

    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={ssrContext.recaptchaKey}
            scriptProps={{ async: true }}
        >
            <FormProvider {...form}>
                <SettleFreelancerForm onSubmit={handleSubmit} step={step} setStep={setStep} />
            </FormProvider>
        </GoogleReCaptchaProvider>
    );
}
