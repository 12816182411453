import { useTranslation } from 'react-i18next';

export const LangCurrency = () => {
    const [, { language }] = useTranslation();
    if (language !== 'en') return <></>;

    const userLanguage = navigator.language;
    if (userLanguage.startsWith('es')) return <>$</>;

    return <>€</>;
};
