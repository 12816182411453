import { useConst } from '@chakra-ui/react';
import { useCallback, useRef } from 'react';

import { CategoryChoose } from '../../components/CategoryChoose/CategoryChoose';
import { CategoryTreeContextProvider } from '../../contexts/CategoryTreeContext';
import {
    type CategoryTreeItemType,
    type FeaturedSubcategoryType,
} from '../../hooks/useCategoryTreeStore';

export type FastCategoryTreeInitialData = {
    featured: FeaturedSubcategoryType[];
    categories: CategoryTreeItemType[];
};

export type FastCategoryTreeProps = {
    initial: string;

    /**
     * HTML id of the input element that will be updated with the selected subcategory id
     * @example
     * <FastCategoryTree for="subcategory" initial="{}" />
     */
    for?: string;
};

export function FastCategoryTree(props: FastCategoryTreeProps) {
    const initialData = useConst<FastCategoryTreeInitialData>(JSON.parse(props.initial));
    const featured = useConst(Object.values(initialData?.featured ?? {}));
    const categories = useConst(Object.values(initialData?.categories ?? {}));
    const input = useRef<HTMLInputElement>(
        props?.for !== undefined ? (document.getElementById(props?.for) as HTMLInputElement) : null,
    );
    const initialSubcategory = useConst(
        Boolean(input.current?.value) &&
            typeof input.current?.value === 'string' &&
            !isNaN(parseInt(input.current?.value, 10))
            ? parseInt(input.current?.value, 10)
            : null,
    );

    const onChange = useCallback(
        (subcategory: number | null) => {
            if (input.current === null) return;
            input.current.value = String(subcategory ?? '');
        },
        [input],
    );

    return (
        <CategoryTreeContextProvider
            featured={featured}
            categories={categories}
            initialSubcategory={initialSubcategory}
            onChange={onChange}
        >
            <CategoryChoose />
        </CategoryTreeContextProvider>
    );
}
