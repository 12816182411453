import { useConst } from '@chakra-ui/react';

import { type InitialProps } from '@/modules/calculator-core/contexts/CalculatorContext';
import { CalculatorProvider } from '@/modules/calculator-core/components/CalculatorProvider';

import { SecpayCalculatorComponent } from '../../components/SecpayCalculatorComponent';

export type SecpayCalculatorInitialProps = {
    offcopyrighttransfer?: string;
} & InitialProps;

export const SecpayCalculator = ({
    offcopyrighttransfer: offCopyrightTransfer,
    ...props
}: SecpayCalculatorInitialProps) => {
    const allowAutoStart = useConst(() => {
        const payment: HTMLInputElement | null = document.querySelector('#id_payment');
        const copyrightTransfer: HTMLInputElement | null =
            document.querySelector('#id_copyright_transfer');

        return (
            payment !== null &&
            payment?.value !== '' &&
            (offCopyrightTransfer === undefined ||
                (copyrightTransfer !== null && copyrightTransfer?.value !== ''))
        );
    });

    return (
        <CalculatorProvider {...props} allowAutoStart={allowAutoStart}>
            <SecpayCalculatorComponent showCopyrightTransfer={offCopyrightTransfer === undefined} />
        </CalculatorProvider>
    );
};
