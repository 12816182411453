import { CalculationDetailsBase } from '@/modules/calculator-core/components/CalculationDetails';

import { useSummaryContext } from '../../contexts/SummaryContext';
import { useSummaryLanguage } from '../../hooks/useSummaryLanguage';

export const CalculationDetails = CalculationDetailsBase.bind(
    {},
    useSummaryContext,
    useSummaryLanguage,
    () => ({ isPersonalCommission: false }),
);
