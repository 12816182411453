import { clientApi } from '../clientApi';

export type SettleFreelancerContactPayload = {
    firstName: string;
    lastName: string;
    email: string;
    message: string;
} & { 'g-recaptcha-response': string };

export async function settleFreelancerContact(data: SettleFreelancerContactPayload) {
    return await clientApi
        .post('settle-freelancer/contact/', {
            throwHttpErrors: false,
            json: {
                'g-recaptcha-response': data['g-recaptcha-response'],
                first_name: data.firstName,
                last_name: data.lastName,
                email: data.email,
                message: data.message,
            },
        })
        .json<any>();
}
