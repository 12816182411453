import { type ComponentProps } from 'react';
import { chakra } from '@chakra-ui/react';
import styled from '@emotion/styled';

export type FieldVatInfoProps = { value: number | string | null } & ComponentProps<
    typeof TextFieldVatInfo
>;

export const FieldVatInfo = ({ value, ...props }: FieldVatInfoProps) => {
    if (value === null) return <></>;

    return (
        <TextFieldVatInfo
            ml={`${
                value.toString().length < 7
                    ? value.toString().length * 12
                    : value.toString().length * 9.5
            }px`}
            top="-4px"
            {...props}
        >
            +VAT
        </TextFieldVatInfo>
    );
};

export const TextFieldVatInfo = styled(chakra.span)`
    color: var(--ck-colors-general-heading);
    font-weight: bold;
    font-size: var(--ck-fontSizes-xs);
    user-select: none;
    pointer-events: none;
    letter-spacing: 2px;
    position: absolute;
    left: 0;
    padding-top: var(--ck-space-9);
    padding-left: 3.3rem;
`;
