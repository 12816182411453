import { CalculatorProvider } from '@/modules/calculator-core/components/CalculatorProvider';
import { type InitialProps } from '@/modules/calculator-core/contexts/CalculatorContext';

import { PricingCalculatorLayout } from '../../components/PricingCalculatorLayout';

export const PricingCalculator = (props: InitialProps) => (
    <CalculatorProvider allowAutoStart={true} defaultAmount={2500} disallowEmail={true} {...props}>
        <PricingCalculatorLayout />
    </CalculatorProvider>
);
