import { HStack, Image, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { useGlobalContext } from '@/modules/core/contexts/GlobalContext';
import { breakpoint } from '@/modules/design/utils/breakpoint';

import payoneer from './payoneer.svg';
import paypal from './paypal.svg';
import ing from './ing.svg';
import wise from './wise.png';

export const PaymentMethodsLogos = () => {
    const { t } = useTranslation();
    const { currentLanguage } = useGlobalContext();

    return (
        <PaymentMethodsLogosStack>
            <TextPayment>{t('calculator_landing.layout_section.payments_info')}</TextPayment>
            {currentLanguage === 'pl' ? (
                <Image src={ing} alt="Ing" loading="lazy" height="18px" />
            ) : (
                <Image src={payoneer} alt="Payoneer" loading="lazy" />
            )}
            <Image src={paypal} alt="PayPal" loading="lazy" height="22px" />
            <Image src={wise} alt="Wise" loading="lazy" height="17px" />
        </PaymentMethodsLogosStack>
    );
};

const PaymentMethodsLogosStack = styled(HStack)`
    gap: var(--ck-space-1);
    padding-top: var(--ck-space-5);
    flex-wrap: nowrap;
    justify-content: center;

    ${breakpoint('md')} {
        justify-content: flex-start;
    }
`;

const TextPayment = styled(Text)`
    white-space: nowrap;
    font-size: var(--ck-fontSizes-xs);
    color: var(--ck-colors-general-darkBlue);
    flex-basis: 100%;
    text-align: center;
    line-height: 160%;
    letter-spacing: 0.36px;

    ${breakpoint('md')} {
        flex-basis: unset;
        text-align: left;
        letter-spacing: normal;
        line-height: normal;
    }
`;
