import { clientApi } from '../clientApi';

export type SettleFreelancerInvoicePayload = {
    freelancerEmail: string;
    message: string;
    firstName: string;
    lastName: string;
    taxId: string;
    email: string;
    newsletterConsent: boolean;
} & { 'g-recaptcha-response': string };

export async function settleFreelancerInvoice(data: SettleFreelancerInvoicePayload) {
    return await clientApi
        .post('settle-freelancer/invoice/', {
            throwHttpErrors: false,
            json: {
                'g-recaptcha-response': data['g-recaptcha-response'],
                freelancer_email: data.freelancerEmail,
                message: data.message,
                first_name: data.firstName,
                last_name: data.lastName,
                tax_id: data.taxId,
                email: data.email,
                newsletter_consent: data.newsletterConsent,
            },
        })
        .json<any>();
}
