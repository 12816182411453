import React, { ComponentProps, createContext, useContext } from 'react';

import { BillingCalculator } from '@/modules/api/hooks/useBilling';
import {
    type UseCalculatorStoreReturn,
    useCalculatorStore,
} from '@/modules/calculator-core/hooks/useCalculatorStore';
import { useBillingDeal } from '@/modules/api/hooks/useBillingDeal';

export interface InitialSummaryProps {
    token: string;
    vat: string;
    billingcalculator: BillingCalculator;
}

export interface SummaryContextType
    extends Pick<
        UseCalculatorStoreReturn,
        'currency' | 'getPayoutItem' | 'getPayinItem' | 'getPriceComponent'
    > {
    billingCalculator: BillingCalculator;
    language: string;
}

export const SummaryContext: React.Context<SummaryContextType> = createContext(null as any);

export function useSummaryContext() {
    return useContext(SummaryContext);
}

export function SummaryContextProvider({
    vat,
    token,
    billingcalculator: billingCalculator,
    children,
}: InitialSummaryProps & ComponentProps<any>) {
    const store = useCalculatorStore();
    useBillingDeal(
        { token },
        {
            onSuccess: (data) => {
                if (data !== undefined) {
                    store.setCalculatorState(data);
                }
            },
        },
    );

    return (
        <SummaryContext.Provider
            value={{
                ...store,
                language: vat.toLowerCase() === 'true' ? 'pl' : 'en',
                billingCalculator,
            }}
        >
            {children}
        </SummaryContext.Provider>
    );
}
