import {
    Button,
    chakra,
    Input,
    Textarea,
    FormLabel,
    FormErrorMessage,
    FormControl,
    Heading,
    Checkbox,
    HStack,
    Text,
    Box,
    Flex,
    SlideFade,
    Link,
} from '@chakra-ui/react';
import { Dispatch, useId } from 'react';
import { useFormContext, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { InfoPopover } from '@/modules/core/components/InfoPopover';
import { useEmailErrorMessage } from '@/modules/core/hooks/useEmailErrorMessage';
import { SettleFreelancerInvoicePayload } from '@/modules/api/services/settle-freelancer-invoice';
import { useReCaptcha } from '@/modules/core/hooks/useReCaptcha';

export type SettleFreelancerFormPayload = Omit<
    SettleFreelancerInvoicePayload,
    'g-recaptcha-response'
> & {
    acceptance: boolean;
};

export function useSettleFreelancerForm() {
    return useForm<SettleFreelancerFormPayload>({
        mode: 'onBlur',
        defaultValues: {
            email: '',
            freelancerEmail: '',
            message: '',
            firstName: '',
            lastName: '',
            taxId: '',
            acceptance: false,
            newsletterConsent: false,
        },
    });
}

export type SettleFreelancerFormProps = {
    onSubmit: (data: SettleFreelancerInvoicePayload) => any;
    step: number;
    setStep: Dispatch<number>;
};

export default function SettleFreelancerForm({
    onSubmit,
    setStep,
    step,
}: SettleFreelancerFormProps) {
    const { t } = useTranslation();
    const form = useFormContext<any>();
    const errors = form.formState.errors;
    const id = useId();
    const required = t('common.errors.required');
    const email = useEmailErrorMessage();
    const { executeRecaptcha } = useReCaptcha();

    async function handleSubmit(data: any) {
        if (executeRecaptcha === undefined) {
            throw new Error('Recaptcha has not been loaded.');
        }
        const recaptchaToken = await executeRecaptcha('settle_freelancer_invoice');
        await onSubmit({
            ...data,
            'g-recaptcha-response': recaptchaToken,
        });
    }

    async function handleFirstStepValidation() {
        await form.trigger('freelancerEmail');
        await form.trigger('message');
        await form.trigger('acceptance');

        const freelancerEmail = form.getFieldState('freelancerEmail').invalid;
        const message = form.getFieldState('message').invalid;
        const acceptance = form.getFieldState('acceptance').invalid;

        if (!freelancerEmail && !message && !acceptance) {
            setStep(2);
        }
    }

    return (
        <Box
            as={chakra.form}
            onSubmit={form.handleSubmit(handleSubmit) as any}
            py="12"
            px={{ base: '6', md: '12' }}
            maxW="container.md"
            mx="auto"
            bg="white"
            my="4"
            borderRadius="sm"
            boxShadow="0px 10px 20px 0px rgba(65, 55, 181, 0.10)"
            border="1px solid"
            borderColor="general.sectionBorder"
        >
            <SlideFade in={step === 1}>
                <Flex display={step === 1 ? 'flex' : 'none'} flexDir="column" gap="6">
                    <FormControl
                        isInvalid={errors?.freelancerEmail !== undefined}
                        variant="future"
                        colorScheme="base"
                    >
                        <HStack as={FormLabel} htmlFor={`${id}-freelancerEmail`} gap="1">
                            <Text>
                                {t('settle_freelancer.settle_freelancer_form.freelancerEmail')}
                            </Text>
                            <InfoPopover>
                                {t('settle_freelancer.settle_freelancer_form.freelancerEmail_info')}
                            </InfoPopover>
                        </HStack>
                        <Input
                            type="email"
                            {...form.register('freelancerEmail', {
                                required,
                                pattern: email,
                                onBlur: () => {
                                    form.trigger('freelancerEmail').finally(() => {});
                                },
                            })}
                            id={`${id}-freelancerEmail`}
                            maxW="sm"
                        />
                        <FormErrorMessage>
                            {errors?.freelancerEmail?.message as any}
                        </FormErrorMessage>
                    </FormControl>

                    <FormControl
                        isInvalid={errors?.message !== undefined}
                        variant="future"
                        colorScheme="base"
                    >
                        <HStack as={FormLabel} htmlFor={`${id}-message`} gap="1">
                            <Text>{t('settle_freelancer.settle_freelancer_form.message')}</Text>
                            <InfoPopover>
                                {t('settle_freelancer.settle_freelancer_form.message_info')}
                            </InfoPopover>
                        </HStack>
                        <Textarea
                            {...form.register('message', {
                                required,
                                onBlur: () => {
                                    form.trigger('message').finally(() => {});
                                },
                            })}
                            id={`${id}-message`}
                            maxW="sm"
                        />
                        <FormErrorMessage>{errors?.message?.message as any}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={errors?.acceptance !== undefined} variant="future">
                        <Checkbox
                            {...form.register('acceptance', {
                                required,
                                onBlur: () => {
                                    form.trigger('acceptance').finally(() => {});
                                },
                            })}
                            id={`${id}-acceptance`}
                            variant="future"
                        >
                            <Trans
                                i18nKey={'settle_freelancer.settle_freelancer_form.acceptance'}
                                components={[
                                    <Link
                                        key="0"
                                        href={t('common.url.tos')}
                                        target="_blank"
                                        variant="legacy"
                                    />,
                                    <Link
                                        key="1"
                                        href={t('common.url.privacy_policy')}
                                        target="_blank"
                                        variant="legacy"
                                    />,
                                ]}
                            />
                        </Checkbox>
                        <FormErrorMessage>{errors?.acceptance?.message as any}</FormErrorMessage>
                    </FormControl>

                    <Box textAlign="left">
                        <Button
                            type="button"
                            px="24"
                            onClick={handleFirstStepValidation as any}
                            variant="solid-responsive"
                        >
                            {t('settle_freelancer.settle_freelancer_form.next')}
                        </Button>
                    </Box>
                </Flex>
            </SlideFade>
            <SlideFade in={step === 2}>
                <Flex display={step === 2 ? 'flex' : 'none'} flexDir="column" gap="6">
                    <Heading as="h2" size="lg" fontWeight="normal" color="general.darkBlue">
                        {t('settle_freelancer.settle_freelancer_form.step_2_heading')}
                    </Heading>

                    <FormControl
                        isInvalid={errors?.firstName !== undefined}
                        variant="future"
                        colorScheme="base"
                    >
                        <FormLabel htmlFor={`${id}-firstName`}>
                            {t('settle_freelancer.settle_freelancer_form.firstName')}
                        </FormLabel>
                        <Input
                            {...form.register('firstName', { required })}
                            id={`${id}-firstName`}
                            maxW="sm"
                        />
                        <FormErrorMessage>{errors?.firstName?.message as any}</FormErrorMessage>
                    </FormControl>

                    <FormControl
                        isInvalid={errors?.lastName !== undefined}
                        variant="future"
                        colorScheme="base"
                    >
                        <FormLabel htmlFor={`${id}-lastName`}>
                            {t('settle_freelancer.settle_freelancer_form.lastName')}
                        </FormLabel>
                        <Input
                            {...form.register('lastName', { required })}
                            id={`${id}-lastName`}
                            maxW="sm"
                        />
                        <FormErrorMessage>{errors?.lastName?.message as any}</FormErrorMessage>
                    </FormControl>

                    <FormControl
                        isInvalid={errors?.taxId !== undefined}
                        variant="future"
                        colorScheme="base"
                    >
                        <FormLabel htmlFor={`${id}-taxId`}>
                            <HStack gap="1">
                                <Text>{t('settle_freelancer.settle_freelancer_form.taxId')}</Text>
                                <InfoPopover>
                                    {t('settle_freelancer.settle_freelancer_form.taxId_info')}
                                </InfoPopover>
                            </HStack>
                            <chakra.span fontSize="sm" color="muted">
                                {t('common.field.optional')}
                            </chakra.span>
                        </FormLabel>
                        <Input
                            type="taxId"
                            {...form.register('taxId')}
                            id={`${id}-taxId`}
                            maxW="sm"
                        />
                        <FormErrorMessage>{errors?.taxId?.message as any}</FormErrorMessage>
                    </FormControl>

                    <FormControl
                        isInvalid={errors?.email !== undefined}
                        variant="future"
                        colorScheme="base"
                    >
                        <HStack as={FormLabel} htmlFor={`${id}-email`} gap="1">
                            <Text>{t('settle_freelancer.settle_freelancer_form.email')}</Text>
                            <InfoPopover>
                                {t('settle_freelancer.settle_freelancer_form.email_info')}
                            </InfoPopover>
                        </HStack>
                        <Input
                            type="email"
                            {...form.register('email', { required, pattern: email })}
                            id={`${id}-email`}
                            maxW="sm"
                        />
                        <FormErrorMessage>{errors?.email?.message as any}</FormErrorMessage>
                    </FormControl>

                    <FormControl
                        isInvalid={errors?.newsletterConsent !== undefined}
                        variant="future"
                    >
                        <Checkbox
                            {...form.register('newsletterConsent')}
                            id={`${id}-newsletterConsent`}
                            variant="future"
                        >
                            {t('settle_freelancer.settle_freelancer_form.newsletterConsent')}
                        </Checkbox>
                        <FormErrorMessage>
                            {errors?.newsletterConsent?.message as any}
                        </FormErrorMessage>
                    </FormControl>

                    <Box textAlign="left">
                        <Button
                            type="submit"
                            px="24"
                            isLoading={form.formState.isSubmitting}
                            variant="solid-responsive"
                        >
                            {t('settle_freelancer.settle_freelancer_form.submit')}
                        </Button>
                    </Box>
                </Flex>
            </SlideFade>
        </Box>
    );
}
