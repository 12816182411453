import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { fetchBillingDeal } from '../../services/calculator';
import { type BillingResult } from '../useBilling/useBilling';

export interface BillingDealInputs {
    token: string;
}

export type UseBillingDealOptions = UseQueryOptions<BillingDealInputs, unknown, BillingResult>;

export const useBillingDeal = ({ token }: BillingDealInputs, options?: UseBillingDealOptions) => {
    return useQuery<BillingDealInputs, unknown, BillingResult>(
        ['billing-deal'],
        async () => {
            return await fetchBillingDeal<any>({
                payment_token: token,
            });
        },
        {
            retry: false,
            refetchOnWindowFocus: false,
            ...options,
        },
    );
};
