import { CalculatorProvider } from '@/modules/calculator-core/components/CalculatorProvider';
import { type InitialProps } from '@/modules/calculator-core/contexts/CalculatorContext';

import { LandingCalculatorLayout } from '../../components/LandingCalculatorLayout';

export const EmbeddableLandingCalculator = (props: InitialProps) => (
    <CalculatorProvider allowAutoStart={true} defaultAmount={2500} disallowEmail={true} {...props}>
        <LandingCalculatorLayout isEmbeddable={true} />
    </CalculatorProvider>
);
