import { Flex, Text, VisuallyHidden, VisuallyHiddenInput, chakra } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { type ChangeEventHandler, type ComponentProps, type PropsWithoutRef, useId } from 'react';
import { useTranslation } from 'react-i18next';

import { breakpoint } from '@/modules/design/utils/breakpoint';

import { useCategoryIcon, IconArrowBack } from '../CategoryTreeIcons';
import { useCategoryTreeContext } from '../../contexts/CategoryTreeContext';
import {
    type CategoryTreeItemType,
    type SubcategoryItemType,
} from '../../hooks/useCategoryTreeStore';
import { useSendCategoryGTM } from '../../hooks/useSendCategoryGTM';

export function CategoryTree() {
    const context = useCategoryTreeContext();
    const { t } = useTranslation();

    return (
        <CategoryTreeContainer>
            {context.category !== null && (
                <StyledCategoryContent
                    as="button"
                    title={t('category_tree.show_all')}
                    onClick={() => context.setCategory(null)}
                >
                    <StyledCategoryIcon>
                        <IconArrowBack width="1.4rem" height="1.2rem" />
                    </StyledCategoryIcon>
                    <VisuallyHidden>{t('category_tree.show_all')}</VisuallyHidden>
                </StyledCategoryContent>
            )}
            <CategoryTreeContent>
                {context.categories.map((cat: any) => (
                    <SubcategoryChoose key={cat.id} {...cat} />
                ))}
            </CategoryTreeContent>
        </CategoryTreeContainer>
    );
}

function SubcategoryChoose(props: PropsWithoutRef<CategoryTreeItemType>) {
    const { subcategories = [], name, id, icon } = props;
    const context = useCategoryTreeContext();
    const htmlId = useId();

    const Icon = useCategoryIcon(icon);
    const sendCategoryGTM = useSendCategoryGTM();

    return (
        <CategoryTreeContent
            display={context.category === null || context.category === id ? 'flex' : 'none'}
        >
            <StyledCategoryContent
                as="button"
                disabled={context.category === id}
                onClick={() => {
                    if (context.category === null) {
                        context.setCategory(id);
                    }
                }}
            >
                <StyledCategoryIcon>
                    <Icon width="2rem" height="2rem" />
                </StyledCategoryIcon>
                <Text fontSize="sm">{name}</Text>
            </StyledCategoryContent>

            <StyledCategorySubcategories display={context.category === id ? 'flex' : 'none'}>
                {subcategories.map((subcat: any) => (
                    <SubcategoryItem
                        key={subcat.id}
                        {...subcat}
                        htmlId={htmlId}
                        onClick={() => context.setSubcategory(subcat.id)}
                        onChange={sendCategoryGTM}
                        isSelected={subcat.id === context.subcategory}
                    />
                ))}
            </StyledCategorySubcategories>
        </CategoryTreeContent>
    );
}

const CategoryTreeContainer = styled(Flex)`
    align-items: flex-start;
    border-radius: 2rem;
    background: var(--ck-colors-general-skyBlue);
    padding: 2.4rem;
`;

const CategoryTreeContent = styled(Flex)`
    flex-wrap: wrap;
    flex-direction: column;
    gap: 1rem;
`;

export function CategoryItem(
    props: ComponentProps<typeof StyledCategoryItem> & CategoryTreeItemType,
) {
    const { name, id, onClick, icon, ...rest } = props;

    const Icon = useCategoryIcon(icon);

    return (
        <StyledCategoryItem {...rest}>
            <StyledCategoryContent onClick={onClick} tabIndex="0" as="button">
                <VisuallyHiddenInput type="radio" name="category" value={id} />
                <StyledCategoryIcon>
                    <Icon width="3.2rem" height="3.2rem" />
                </StyledCategoryIcon>
                <Text>{name}</Text>
            </StyledCategoryContent>
        </StyledCategoryItem>
    );
}

export function SubcategoryItem(
    props: ComponentProps<typeof StyledCategoryItem> &
        SubcategoryItemType & {
            isSelected: boolean;
            htmlId: string;
            onChange?: ChangeEventHandler<HTMLInputElement>;
        },
) {
    const { name, id, onClick, onChange, isSelected, htmlId, ...rest } = props;

    return (
        <StyledCategoryItem {...rest}>
            <StyledCategoryContent onClick={onClick} isSelected={isSelected}>
                <VisuallyHiddenInput
                    type="radio"
                    name={htmlId}
                    value={id}
                    tabIndex={0}
                    onChange={onChange}
                />
                <Text>{name}</Text>
            </StyledCategoryContent>
        </StyledCategoryItem>
    );
}

const StyledCategoryItem = styled(Flex)`
    align-items: flex-start;
    gap: 2.4rem;
    background: rgba(73, 85, 255, 0);
    border-radius: 1.6rem;
`;

const StyledCategoryContent = styled(chakra.label, {
    shouldForwardProp: (prop) => prop !== 'isSelected',
})`
    display: flex;
    padding: 0.8rem 1.2rem;
    align-items: center;
    justify-content: flex-start;
    gap: 1.4rem;
    cursor: pointer;
    border-radius: 2rem;
    color: var(--ck-colors-general-font);
    font-family: var(--ck-font-primary, 'Lato');
    font-size: 1.4rem;
    font-weight: normal;
    line-height: normal;
    transition: 0.15s;

    &:hover,
    &:focus-within,
    &:focus {
        background: var(--ck-colors-secondary-500);
        color: var(--ck-colors-blue-500);
    }

    &:active {
        background: var(--ck-colors-general-backgroundActive);
        color: var(--ck-colors-blue-500);
    }

    ${(props: any) =>
        Boolean(props?.isSelected) &&
        `
        &,
        &:hover,
        &:focus,
        &:focus-within {
            background: var(--ck-colors-blue-500);
            color: var(--ck-colors-blue-font);
            font-weight: bold;
        }
    `}

    &:disabled,
    &:disabled:hover,
    &:disabled:focus,
    &:disabled:focus-within
    &:disabled:active {
        background: transparent;
        color: var(--ck-colors-general-font);
        cursor: default;
        font-weight: bold;
        transition: none;
    }
`;

const StyledCategoryIcon = styled(Flex)`
    width: 2.4rem;
    height: 2.4rem;
    align-items: center;
    justify-content: center;
    color: var(--ck-colors-general-gray);
`;

const StyledCategorySubcategories = styled(Flex)`
    flex-direction: column;
    gap: 0.8rem;
    padding: 0;

    ${breakpoint('sm')} {
        padding: 0 3.6rem;
    }
`;
