import { useCalculatorContext } from '../../contexts/CalculatorContext';

export const usePersonalCommission = () => {
    const { paymentDetails, getPriceComponent } = useCalculatorContext();

    return {
        isPersonalCommission:
            paymentDetails.personalCommission === true ||
            getPriceComponent('is_personal_commission') !== null,
    };
};
