import { registerComponent } from '@/modules/app/services/registerComponent';
import { LandingCalculator } from '@/modules/calculator-landing/pages/LandingCalculator';
import { PricingCalculator } from '@/modules/calculator-landing/pages/PricingCalculator';
import { EmbeddableLandingCalculator } from '@/modules/calculator-landing/pages/EmbeddableLandingCalculator';
import { FastCalculator } from '@/modules/calculator-fast/pages/FastCalculator';
import { SecpayCalculator } from '@/modules/calculator-secpay/pages/SecpayCalculator';
import { SecpaySummary } from '@/modules/calculator-summary/pages/SecpaySummary';
import { FreelancerDealSummary } from '@/modules/calculator-summary/pages/FreelancerDealSummary';
import { EmployerDealSummary } from '@/modules/calculator-summary/pages/EmployerDealSummary';
import { FastCategoryTree } from '@/modules/category-tree/pages/FastCategoryTree';
import { SettleFreelancer } from '@/modules/settle-freelancer/pages/SettleFreelancer';
import { LetsTalk } from '@/modules/settle-freelancer/pages/LetsTalk';

registerComponent('landing-calculator', LandingCalculator, ['initial']);
registerComponent('embeddable-landing-calculator', EmbeddableLandingCalculator, ['initial']);
registerComponent('pricing-calculator', PricingCalculator, ['initial']);
registerComponent('secpay-calculator', SecpayCalculator, ['initial']);
registerComponent('fast-calculator', FastCalculator, [
    'initial',
    'legalstatus',
    'country',
    'residence',
]);
registerComponent('secpay-summary', SecpaySummary, ['initial']);
registerComponent('freelancer-deal-summary', FreelancerDealSummary, [
    'token',
    'vat',
    'billingcalculator',
]);
registerComponent('employer-deal-summary', EmployerDealSummary, [
    'token',
    'vat',
    'billingcalculator',
]);
registerComponent('fast-category-tree', FastCategoryTree, ['initial', 'for']);
registerComponent('settle-freelancer-form', SettleFreelancer, []);
registerComponent('lets-talk', LetsTalk, []);
