import { Container, chakra, Stack, Heading, Grid, GridItem, HStack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { NumericFormat } from '@/modules/core/components/NumericFormat';

import { useSummaryContext } from '../../contexts/SummaryContext';
import { CalculationDetails } from '../CalculationDetails';
import { useSummaryLanguage } from '../../hooks/useSummaryLanguage';

const FreelancerSection = () => {
    const { t } = useTranslation();
    const { getPayoutItem: getItem, currency } = useSummaryContext();

    return (
        <Stack
            spacing={2.5}
            mb="0.7rem"
            lineHeight="2.4rem"
            fontSize="sm"
            color="general.lightGray"
        >
            <Heading as="h6" fontSize="1.5rem" color="general.font">
                {t('calculator_summary.employer_summary.freelancer_header')}
            </Heading>

            <HStack justifyContent="space-between">
                <Text>{t('calculator.payout')}:</Text>
                <Text color="general.green" fontWeight="bold">
                    <NumericFormat value={getItem('total')} suffix={currency} />
                </Text>
            </HStack>
        </Stack>
    );
};

const EmployerSection = () => {
    const { t } = useTranslation();
    const { getPayinItem: getItem, currency } = useSummaryContext();
    const { language } = useSummaryLanguage();

    return (
        <Stack
            spacing={2.5}
            mb="0.7rem"
            lineHeight="2.4rem"
            fontSize="sm"
            color="general.lightGray"
        >
            <Heading as="h6" fontSize="1.5rem" color="general.font">
                {t('calculator_summary.employer_summary.employer_header')}
            </Heading>

            {language === 'pl' ? (
                <>
                    <HStack justifyContent="space-between">
                        <Text>{t('calculator_summary.employer_summary.net')}:</Text>
                        <Text color="general.blue" fontWeight="bold">
                            <NumericFormat value={getItem('net_amount')} suffix={currency} />
                        </Text>
                    </HStack>

                    {getItem('vat_amount') !== null && (
                        <HStack justifyContent="space-between">
                            <Text>VAT:</Text>
                            <Text color="general.darkBlue" fontWeight="bold">
                                <NumericFormat value={getItem('vat_amount')} suffix={currency} />
                            </Text>
                        </HStack>
                    )}

                    <HStack justifyContent="space-between">
                        <Text>{t('calculator_summary.employer_summary.gross')}:</Text>
                        <Text color="general.darkBlue" fontWeight="bold">
                            <NumericFormat value={getItem('gross_amount')} suffix={currency} />
                        </Text>
                    </HStack>
                </>
            ) : (
                <HStack justifyContent="space-between">
                    <Text>{t('calculator.invoice')}:</Text>
                    <Text color="general.blue" fontWeight="bold">
                        <NumericFormat value={getItem('net_amount')} suffix={currency} />
                    </Text>
                </HStack>
            )}
        </Stack>
    );
};

export const EmployerSummary = () => {
    return (
        <Container maxW="container.xl" px={0}>
            <chakra.div
                bg="general.skyBlue"
                borderRadius="xl"
                p="4.8rem 2.4rem 2.6rem"
                maxW="644px"
            >
                <Grid
                    templateColumns={{
                        base: '1fr',
                        md: '1fr 1fr',
                    }}
                    columnGap={15}
                    rowGap={6}
                >
                    <GridItem>
                        <EmployerSection />
                    </GridItem>
                    <GridItem>
                        <FreelancerSection />
                    </GridItem>
                </Grid>

                <CalculationDetails advanced={false} mt={8} />
            </chakra.div>
        </Container>
    );
};
