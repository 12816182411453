import { Container, chakra, Stack, Heading, Grid, GridItem, HStack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { NumericFormat } from '@/modules/core/components/NumericFormat';
import { BillingCalculator } from '@/modules/api/hooks/useBilling';

import { useSummaryContext } from '../../contexts/SummaryContext';
import { CalculationDetails } from '../CalculationDetails';

const PayoutSection = () => {
    const { t } = useTranslation();
    const { getPayoutItem: getItem, currency } = useSummaryContext();

    return (
        <>
            <Stack spacing={2.5} mb="0.7rem">
                <Heading as="h6" fontSize="1.5rem" color="general.font">
                    {t('calculator.freelancer_header')}
                </Heading>
            </Stack>

            <Stack lineHeight="2.4rem" fontSize="sm" color="general.lightGray">
                <HStack justifyContent="space-between">
                    <Text>{t('calculator_summary.freelancer_summary.payout_amount')}:</Text>
                    <Text color="general.green">
                        <chakra.span fontWeight="bold">
                            <NumericFormat value={getItem('total')} suffix={currency} />
                        </chakra.span>
                        {getItem('vat_amount') !== null && ' + VAT'}
                    </Text>
                </HStack>
            </Stack>
        </>
    );
};

const PayinSection = () => {
    const { t } = useTranslation();
    const { getPayinItem: getItem, currency } = useSummaryContext();

    return (
        <>
            <Stack spacing={2.5} mb="0.7rem">
                <Heading as="h6" fontSize="1.5rem" color="general.font">
                    {t('calculator.employer_header')}
                </Heading>
            </Stack>

            <HStack
                justifyContent="space-between"
                lineHeight="2.4rem"
                fontSize="sm"
                color="general.lightGray"
            >
                <Text>{t('calculator.invoice')}:</Text>
                <Text color="general.blue">
                    <chakra.span fontWeight="bold">
                        <NumericFormat value={getItem('net_amount')} suffix={currency} />
                    </chakra.span>
                    {getItem('vat_amount') !== null && ' + VAT'}
                </Text>
            </HStack>
        </>
    );
};

export const FreelancerSummary = () => {
    const { billingCalculator } = useSummaryContext();

    const isGross =
        billingCalculator === BillingCalculator.G2N ||
        billingCalculator === BillingCalculator.GROSS;

    return (
        <Container maxW="container.xl" px={0}>
            <chakra.div
                bg="general.skyBlue"
                borderRadius="xl"
                p="4.8rem 2.4rem 2.6rem"
                maxW="644px"
            >
                <Grid
                    templateColumns={{
                        base: '1fr',
                        md: '1fr 1fr',
                    }}
                    columnGap={15}
                    rowGap={6}
                >
                    <GridItem>{isGross ? <PayinSection /> : <PayoutSection />}</GridItem>
                    <GridItem>{isGross ? <PayoutSection /> : <PayinSection />}</GridItem>
                </Grid>

                <CalculationDetails mt={8} />
            </chakra.div>
        </Container>
    );
};
