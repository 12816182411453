import { useFormContext } from 'react-hook-form';

export const useCalculatorLanguage = () => {
    const { watch } = useFormContext();
    const contractorCountry: string = watch('contractorCountry');

    return {
        language: contractorCountry?.toLowerCase(),
    };
};
