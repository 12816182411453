import { clientApi } from '../clientApi';

export async function fetchBilling<T>(values: T) {
    return await clientApi
        .post('billing/', {
            json: values,
        })
        .json<any>();
}

export async function fetchBillingDeal<T>(values: T) {
    return await clientApi
        .post('billing/deal/', {
            json: values,
        })
        .json<any>();
}
