import { Container, Stack, chakra } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';

import { useInitCalculatorFromExternalForm } from '@/modules/calculator-core/hooks/useInitCalculatorFromExternalForm';

import { CalculatorFormLayout } from '../CalculatorFormLayout';
import { CopyrightTransferRadio } from '../CopyrightTransferRadio';

export type SecpayCalculatorComponentProps = {
    showCopyrightTransfer?: boolean;
};

export const SecpayCalculatorComponent = ({
    showCopyrightTransfer,
}: SecpayCalculatorComponentProps) => {
    const { setValue } = useFormContext();
    const { initCalculator } = useInitCalculatorFromExternalForm();

    useEffect(() => {
        initCalculator();

        const copyrightTransfer: HTMLInputElement | null =
            document.querySelector('#id_copyright_transfer');
        if (copyrightTransfer !== null && copyrightTransfer?.value !== '') {
            setValue('copyrightTransfer', copyrightTransfer?.value, { shouldValidate: true });
        }
    }, []);

    return (
        <Container maxW="container.xl" px={0}>
            <Stack spacing="3rem">
                {showCopyrightTransfer === true && (
                    <CopyrightTransferRadio
                        onChange={(value: string) => {
                            const input: HTMLInputElement | null =
                                document.querySelector('#id_copyright_transfer');
                            if (input !== null) {
                                input.value = value;
                            }
                        }}
                    />
                )}

                <chakra.div
                    bg="general.skyBlue"
                    borderRadius="xl"
                    py="2.4rem"
                    px={{ base: '2.4rem', lg: '1.2rem', xl: '2.4rem' }}
                    mx="-2.4rem !important"
                >
                    <CalculatorFormLayout />
                </chakra.div>
            </Stack>
        </Container>
    );
};
